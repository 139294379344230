import React, {useEffect} from "react";
import {ISelectUser, IUser} from "../../../types/users";
import styled from "styled-components";
import {sleep} from "../../../utils";
import {Modal} from "../../../modal/Modal";
import {AddMemberForm} from "../../../modal/AddMemberForm";
import {ITeam} from "../../../types/match";
import { v4 as uuidv4 } from 'uuid';

interface BasketballUserSelectProps {
    users: ISelectUser[];
    handleAnonymousUserAddButtonClick: (newUser: ISelectUser) => void;
    handleNavigateNextStep: any;
    updateUsers: (users: ISelectUser[]) => void;
}

export const BasketballUserSelect: React.FC<BasketballUserSelectProps> = (props: BasketballUserSelectProps) => {
    const [isAnonymousUserAddModalOpen, setIsAnonymousUserAddModalOpen] = React.useState(false);

    // handler
    /** 게임에 참여할 유저들을 선택할 때 */
    const handleUserClicked = (index: number) => {
        const newUsers = [...props.users];
        newUsers[index].selected = !newUsers[index].selected;
        props.updateUsers(newUsers);
    }

    /** 익명의 유저 추가 모달 띄우는 버튼 */
    const handleModalOpenButtonClicked = () => {
        setIsAnonymousUserAddModalOpen(true);
    }

    /** 익명의 유저 모달내에서 추가하기 버튼 누르는 경우 */
    const handleAnonymousUserAddButtonClick = async (name: string) => {
        const newUser: ISelectUser = {
            id: undefined,
            name: name,
            username: uuidv4().toString(),
            selected: true,
        }

        props.handleAnonymousUserAddButtonClick(newUser);

        await sleep(0.5)
    }

    const handleNavigateNextStep = () => {
        props.handleNavigateNextStep(props.users)
    }

    const isNextButtonDisabled = () => {
        return props.users.filter(user => user.selected).length === 0;
    }

    /** 전체 선택 버튼 누르는 경우 */
    const handleAllSelectButtonClicked = () => {
        props.updateUsers(props.users.map(user => ({...user, selected: true})))
    }

    /** 전체 해제 버튼 누르는 경우 */
    const handleAllDeselectButtonClicked = () => {
        props.updateUsers(props.users.map(user => ({...user, selected: false})))
    }

    // util
    const isAnonymousUser = (user: IUser) => {
        return user.id === -1;
    }

    return (
        <>
            {/* 컨텐츠 매치 참여인원 선정 */}
            <Wrapper>
                <NavBar>
                    <NextNavigationButton
                        disabled={isNextButtonDisabled()}
                        onClick={handleNavigateNextStep}
                    >
                        다음
                    </NextNavigationButton>
                </NavBar>
                <TopBar>
                    <Title>게임 인원 선택</Title>
                </TopBar>
                <OptionBar>
                    <SelectCount>{`${props.users.filter(user => user.selected).length} / ${props.users.length}명`}</SelectCount>
                    <AnonymousUserAddButton onClick={handleModalOpenButtonClicked}>직접 추가하기</AnonymousUserAddButton>
                    <OptionButton onClick={handleAllSelectButtonClicked}>전체 선택</OptionButton>
                    <OptionButton onClick={handleAllDeselectButtonClicked}>전체 해제</OptionButton>
                </OptionBar>
                <UserWrapper>
                    {props.users.map((user, index) => {
                        return (
                        <User
                            key={user.username}
                            selected={user.selected}
                            onClick={() => handleUserClicked(index)}
                        >
                            <UserName>{isAnonymousUser(user)? `${user.name} (익명)`: user.name}</UserName>
                            <UserSelectInput type="checkbox" onChange={() => {}} checked={user.selected}/>
                        </User>
                        )
                    })}

                </UserWrapper>
            </Wrapper>

            {/* 직접 추가하기 모달 */}
            <Modal
                showModal={isAnonymousUserAddModalOpen}
                closeModal={() => {
                    setIsAnonymousUserAddModalOpen(false)
                }}
                children={
                    <AddCandidateUserForm
                        users={props.users}
                        handleAddMemberButtonClick={handleAnonymousUserAddButtonClick}
                        close={() => {
                            setIsAnonymousUserAddModalOpen(false)
                        }}
                    />
                }/>
        </>
    )
}

interface AddCandidateUserProps {
    users: IUser[];
    handleAddMemberButtonClick: (name: string) => void;
    close: () => void;
}

export const AddCandidateUserForm = ({users, handleAddMemberButtonClick, close}: AddCandidateUserProps) => {
    const [name, setName] = React.useState('');

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setName(value)
    }

    return (
        <div className={'add-member-modal'}>
            <InfoWrapper>
                <div>현재 인원 : {users.length}</div>
            </InfoWrapper>
            <Subject>아직 회원가입 되어 있지 않은 유저 추가하기</Subject>
            <div className={'input-box'}>
                <label htmlFor='name'>이름</label>
                <input id='name-input' type='text' value={name} onChange={handleNameChange} placeholder='이름'></input>
            </div>
            <div className={'input-box'}>
                <button className={'add-btn'} onClick={() => {
                    handleAddMemberButtonClick(name)
                }}>추가하기
                </button>
            </div>
        </div>
    );
}


const InfoWrapper = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

const Title = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 2rem;
`;

const Subject = styled.h3`
    width: 100%;
    margin-bottom: 1rem;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    padding: 0 1rem;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
    }

    &::-webkit-scrollbar-track {
        background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #333;
    }
`;

const Button = styled.button`
    height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid black;
    cursor: pointer;
;`

const Bar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
`;

const TopBar = styled(Bar)`
    justify-content: flex-end;
`;

const NavBar = styled(Bar)`
    justify-content: flex-end;
    margin: 1rem 0 0 0;
`;

const OptionBar = styled(Bar)`
    justify-content: flex-end;
`;

const OptionButton = styled(Button)`
    margin: 0 0 0 1rem;
`;

const AnonymousUserAddButton = styled(Button)`
    height: 2rem;
    min-width: 6rem;
    border-radius: 0.5rem;
    border: 1px solid black;
`;

const SelectCount = styled.div`
    min-width: 5rem;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
`;

const NextNavigationButton = styled(Button)`
    min-width: 6rem;
    
    &:disabled {
        border: 1px solid #a2a1a1;
        cursor: not-allowed;
    }
`;

const UserWrapper = styled.div`
    width: 100%;
    height: calc(100% - 4rem);
    box-sizing: border-box;
    overflow-y: auto;
    scrollbar-width: none;
`;

const User = styled.div<{ selected: boolean }>`
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    border-radius: 0.5rem;
    box-shadow: -1px 1px 5px 0 rgb(203, 203, 203);
    margin: 0 0 1rem 0;
    background: ${props => props.selected ? '#676767' : 'white'};
    cursor: pointer;
    color: ${props => props.selected ? 'white' : 'black'};
    
    &:hover {
        background: #a2a1a1;
        color: white;
    }
`;

const UserName = styled.div`
    font-size: 1.1rem;
    letter-spacing: 0;
    font-weight: bold;
`;

const UserSelectInput = styled.input`
    width: 1rem;
    height: 1rem;
`;
