import React from "react";
import {BadmintonTeamSpec} from "../components/match/badminton/BadmintonTeamSpec";
import {BadmintonScoreBoard} from "../components/match/badminton/BadmintonScoreBoard";

export const BadmintonRecordPage = ({ selected }: { selected: boolean }) => {
    const [teams, setTeams] = React.useState([
        {
            users: ['남영우', '김영훈'],
            teamName: '팀 1'
        },
        {
            users: ['양승필', '유지현'],
            teamName: '팀 2'
        }
    ]);

    const [scoreRecord, setScoreRecord] = React.useState(new Array(25).fill(-1));
    const [totalScore, setTotalScore] = React.useState(0);

    const [startedAt, setStartedAt] = React.useState(new Date());
    const [startFlag, setStartFlag] = React.useState(false);
    const [endedAt, setEndedAt] = React.useState(new Date());
    const [endFlag, setEndFlag] = React.useState(false);

    const teamScore = [
        scoreRecord.filter((teamIndex: number, score: number) => score < totalScore && teamIndex === 0).length,
        scoreRecord.filter((teamIndex: number, score: number) => score < totalScore && teamIndex === 1).length
    ]

    const isStart = teamScore[0] + teamScore[1] > 0;
    const isDeuce = teamScore[0] >= 24 && teamScore[1] >= 24 && teamScore[0] === teamScore[1];
    const isMatchPoint =  (teamScore[0] + teamScore[1] >= 24 * 2 && (Math.abs(teamScore[0] - teamScore[1]) === 1)) || (teamScore[0] + teamScore[1] < 24 * 2 && (teamScore[0] === 24 || teamScore[1] === 24));
    const isFinished = (teamScore[0] + teamScore[1] >= 24 * 2 && (Math.abs(teamScore[0] - teamScore[1]) === 2)) || (teamScore[0] + teamScore[1] < 24 * 2 && (teamScore[0] === 25 || teamScore[1] === 25));

    // 한번 초기화
    if (isStart && startFlag === false) {
        setStartedAt(new Date());
        setStartFlag(true);
    }

    if (isFinished && endFlag === false) {
        setEndedAt(new Date());
        setEndFlag(true);
    }


    return (
        <div className={'badminton'}  style={{ display: selected ? 'flex' : 'none' }}>
            <h1>배드민턴 기록지</h1>
            {BadmintonTeamSpec(teams, isFinished, scoreRecord, totalScore, setTotalScore, setScoreRecord, teamScore)}

            {startFlag && (<div className={'game-status'}>시작 시간: {startedAt.toLocaleString()}</div>)}
            {endFlag && (<div className={'game-status'}>종료 시간: {endedAt.toLocaleString()}</div>)}

            {!isFinished && !isMatchPoint && !isDeuce && (<div className={'game-status'}>{'\b'}</div>)}
            {isDeuce && (<div className={'game-status'}>듀 스</div>)}
            {isMatchPoint && (<div className={'game-status'}>매치 포인트</div>)}
            {isFinished && (<div className={'game-status'}>게임 종료</div>)}

            {/*{ScoreBoard(scoreRecord, totalScore, teams)}*/}
            {BadmintonScoreBoard(scoreRecord, totalScore, teams, setTotalScore, setScoreRecord, isFinished)}
        </div>
    );
};
