import {api} from "../axiosInstance";
import {
    ICreateMatchRequest,
    ICreateMatchResponse,
    IMatchListResponse,
    IOneMatchResponse, IUpdateMatchRequest,
    IUpdateMatchResponse
} from "../types/match";
import {IUsersResponse} from "../types/users";

export const createMatch = async (payload: ICreateMatchRequest): Promise<ICreateMatchResponse> => {
    try {
        const response = await api.post<ICreateMatchResponse>('/match', payload)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const fetchMatchById = async (matchId: number): Promise<IOneMatchResponse> => {
    try {
        const response = await api.get<IOneMatchResponse>(`/match/${matchId}`)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const fetchMatchesByTeam = async (teamId: number): Promise<IMatchListResponse> => {
    try {
        const response = await api.get<IMatchListResponse>(`/match/`, {params: { teamId }})
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const fetchMatchesByUser = async (teamId: number): Promise<IMatchListResponse> => {
    try {
        const response = await api.get<IMatchListResponse>(`/match/`, {params: { teamId }})
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateMatch = async (matchId: number, payload: IUpdateMatchRequest): Promise<IUpdateMatchResponse> => {
    try {
        const response = await api.put<IUpdateMatchResponse>(`/match/${matchId}`, payload)
        return response.data;
    } catch (error) {
        throw error;
    }
}
