import {useRecoilState} from "recoil";
import {userState} from "../../state/userState";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BackArrow } from "../../icon/back_arrow.svg";
import {CircleImg} from "../../components/atom/CircleImg";
const UserPage: React.FC = () => {
    const navigate = useNavigate();

    const [user, setUser] = useRecoilState(userState);
    return (
        <>
            <PageLayout>
                <Nav>
                    <IconWrapper onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);

                    }}>
                        <BackArrow/>
                    </IconWrapper>
                    <LogoutButton onClick={() => {
                        setUser(null);
                        navigate("/login", {replace: true});
                    }}>로그아웃
                    </LogoutButton>
                </Nav>

                <CircleImg url={user?.profileImageUrl ?? "/image/user.svg"} width={10} height={10}/>
                <FieldList>
                    <Field>
                        <Label>닉네임</Label>
                        <Value>{user?.name}</Value>
                    </Field>

                    <Field>
                        <Label>토큰</Label>
                        <Value>{user?.token}</Value>
                    </Field>
                </FieldList>
                <textarea style={{height: "5rem", width: "100%"}} value={JSON.stringify(user, null, 2)}></textarea>
            </PageLayout>
        </>
    )

}

const PageLayout = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LogoutButton = styled.button`
    width: 4rem;
    height: 3rem;
    color: white;
    background: inherit;
    border: none;
    border-radius: 1rem;
    cursor: pointer;

`;

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    height: 3rem;
    width: 100%;
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 20;
`;

const Value = styled.div`
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid var(--system-gray);
    background: var(--system-gray);
    border-radius: 1rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5rem;
`;

const Label = styled.div`
    min-width: 5rem;
    text-align: center;
`;

const FieldList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 0 0;
`;

const Field = styled.div`
    width: 100%;
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--system-deepblack);
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
`;

export default UserPage;