import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useLocation} from "react-router-dom";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Content id={"content"}>
            {children}
        </Content>
    )
};



const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    margin: 0 auto;
`;

export default Layout;