import styled from "styled-components";
import {CircleImg} from "../../components/atom/CircleImg";
import { ReactComponent as Arrow } from "../../icon/nav_arrow_gray.svg";
import { ReactComponent as UserIcon } from "../../icon/user.svg";
import GameStartBottomSheet from "../../modal/GameStartBottomSheet";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userState} from "../../state/userState";

interface Sport {
    id: number;
    name: string;
    icon: string;
}

interface Game {
    id: number;
    // sport 부분 enum 으로 변경
    sport: Sport;
    date: Date;
    // location: string;
    summary: any;
}

const HomeV2Page: React.FC = () => {
    const navigate = useNavigate();

    const user = useRecoilValue(userState);

    const sports: Sport[] = localStorage.getItem("fav-spo") ?
        JSON.parse(localStorage.getItem("fav-spo") as string) :
        [];

    const navigateToPlay = () => {
        navigate("/play");
    }
    const gameRecords: Game[] = [
        { id: 1, sport: { id: 1, name: "축구", icon: "/images/sport-icon-soccer.png" },
            date: new Date(),
            summary: { team: [
                    {
                        name: 'team1',
                        player: [
                            { name: 'player1', score: 1 },
                            { name: 'player2', score: 2 },
                            { name: 'player3', score: 3 },
                        ],
                        score: 2,
                    },
                    {
                        name: 'team2',
                        player: [
                            { name: 'player4', score: 1 },
                            { name: 'player5', score: 2 },
                            { name: 'player6', score: 3 },
                        ],
                        score: 3,
                    }
                ]}
        },
        { id: 2, sport: { id: 2, name: "야구", icon: "/images/sport-icon-baseball.png" },
            date: new Date(),
            summary: { team: [
                    {
                        name: 'team1',
                        player: [
                            { name: 'player1', score: 1 },
                            { name: 'player2', score: 2 },
                            { name: 'player3', score: 3 },
                        ],
                        score: 7,
                    },
                    {
                        name: 'team2',
                        player: [
                            { name: 'player4', score: 1 },
                            { name: 'player5', score: 2 },
                            { name: 'player6', score: 3 },
                        ],
                        score: 6
                    }
                ]}
        },
        { id: 1, sport: { id: 1, name: "축구", icon: "/images/sport-icon-soccer.png" },
            date: new Date(),
            summary: { team: [
                    {
                        name: 'team1',
                        player: [
                            { name: 'player1', score: 1 },
                            { name: 'player2', score: 2 },
                            { name: 'player3', score: 3 },
                        ],
                        score: 2,
                    },
                    {
                        name: 'team2',
                        player: [
                            { name: 'player4', score: 1 },
                            { name: 'player5', score: 2 },
                            { name: 'player6', score: 3 },
                        ],
                        score: 3,
                    }
                ]}
        },
        { id: 2, sport: { id: 3, name: "농구", icon: "/images/sport-icon-basketball.png" },
            date: new Date(),
            summary: { team: [
                    {
                        name: 'team1',
                        player: [
                            { name: 'player1', score: 1 },
                            { name: 'player2', score: 2 },
                            { name: 'player3', score: 3 },
                        ],
                        score: 7,
                    },
                    {
                        name: 'team2',
                        player: [
                            { name: 'player4', score: 1 },
                            { name: 'player5', score: 2 },
                            { name: 'player6', score: 3 },
                        ],
                        score: 6
                    }
                ]}
        },
        { id: 1, sport: { id: 1, name: "축구", icon: "/images/sport-icon-soccer.png" },
            date: new Date(),
            summary: { team: [
                    {
                        name: 'team1',
                        player: [
                            { name: 'player1', score: 1 },
                            { name: 'player2', score: 2 },
                            { name: 'player3', score: 3 },
                        ],
                        score: 2,
                    },
                    {
                        name: 'team2',
                        player: [
                            { name: 'player4', score: 1 },
                            { name: 'player5', score: 2 },
                            { name: 'player6', score: 3 },
                        ],
                        score: 3,
                    }
                ]}
        },
    ];

    return (
        <>
            <Nav>
                <Icon>I'm <Highlight>Game</Highlight></Icon>
                <IconWrapper onClick={(e) => {
                    e.preventDefault();
                    if (user) {
                        navigate("/user/me");
                    } else {
                        navigate("/login");
                    }
                }}>
                    <UserIcon />
                </IconWrapper>
            </Nav>
            <Header>
                {/* 유저 프로필 관련 영역 */}
                <ProfileContainer>
                    <CircleImg url={user?.profileImageUrl ?? '/images/user.svg'} width={6} height={6}/>
                    <Greetings>안녕하세요 {user?.name && `${user.name}님`}</Greetings>
                    <SubGreetings>나의 스포츠 경기 기록을 관리해 보세요!</SubGreetings>
                </ProfileContainer>

                {/* 유저의 관심 스포츠 관련 영역 */}
                <FavoriteSportContainer>
                    <NavChangeFavoriteSport onClick={(e) => {
                        e.preventDefault();
                        navigate("/setting/sport/favorite")
                    }}>
                        <Title>{"나의 관심 스포츠"}</Title><Arrow />
                    </NavChangeFavoriteSport>
                    <SportContainer>
                        {sports.map(sport => (
                            <Sport key={sport.id}>
                                <SportIcon url={sport.icon} size={3}/>
                                <SportName>{sport.name}</SportName>
                            </Sport>
                        ))}
                    </SportContainer>
                </FavoriteSportContainer>
            </Header>

            {/* 최근 경기 기록 영역 */}
            <RecentGameRecordContainer>
                <Describe>
                    <Title>나의 최근 경기 기록</Title><Arrow />
                </Describe>

                {gameRecords.map((game, index) => (
                    <GameRecord
                        key={`$game-record-${index}`}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/play/${game.id}/result`);
                        }}
                    >
                        <GameSportIcon url={game.sport.icon} size={2.5}/>
                        <Team>
                            <TeamScore>
                                {game.summary.team[0].score}
                            </TeamScore>

                            <TeamName>
                                {game.summary.team[0].score > game.summary.team[1].score && <WinBadge>승</WinBadge>}
                                {game.summary.team[0].name}
                            </TeamName>
                        </Team>
                        <GameData>
                            <PlayDate>{game.date.toLocaleDateString()}</PlayDate>
                        </GameData>
                        <Team>
                            <TeamScore>

                                {game.summary.team[1].score}
                            </TeamScore>

                            <TeamName>
                                {game.summary.team[1].score > game.summary.team[0].score && <WinBadge>승</WinBadge>}
                                {game.summary.team[1].name}
                            </TeamName>
                        </Team>
                        <NavGameDetail>
                            <Arrow />
                        </NavGameDetail>
                    </GameRecord>
                ))}
            </RecentGameRecordContainer>

            <GameStartBottomSheet
                sport={{icon: "/images/sport-icon-soccer.png"}}
                navigateToPlay={navigateToPlay}
            />
        </>
    );
}

const Describe = styled.div`
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
    color: var(--system-lightgray);
`;

const GameRecord = styled.div`
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    border-radius: 1.5rem;
    background: linear-gradient(#465272, #28293E);
    margin: 0.5rem 0;
    position: relative;
    cursor: pointer;
`;


const Team = styled.div`
    width: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-3);
`;

const TeamScore = styled.div`
    font-size: var(--font-size-3);
    font-weight: 900;
    margin-bottom: 0.5rem;
`;

const TeamName = styled.div`
    font-size: var(--font-size-7);
    font-weight: 500;
    position: relative;
`;

const WinBadge = styled.div`
    width: 1rem;
    line-height: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: var(--brand-green);
    color: #fff;
    position: absolute;
    left: -1.3rem;
    text-align: center;
`;

const GameData = styled.div`
    width: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
`;

const PlayDate = styled.div`
    color: var(--system-lightgray);
`;

const NavGameDetail = styled.div`height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2rem;
`;



const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    height: 3rem;
    //border: 1px white solid;
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 20;
`;

const Icon = styled.div`
    color: #fff;
    font-weight: 900;
`;


const Highlight = styled.span`
    color: var(--brand-blue);
`;

const Header = styled.header`
    margin: 0 1.5rem;
    //border: 1px white solid;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ProfileContainer = styled.div`
    height: 10rem;
    //border: 1px white solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem;
`;

const Greetings = styled.div`
    font-size: var(--font-size-5);
    font-weight: 900;    
`;

const SubGreetings = styled.div`
    font-size: var(--font-size-7);
    font-weight: 500;
    color: var(--system-lightgray);
`;


const FavoriteSportContainer = styled.div`
    width: 100%;
    height: 7rem;
    //border: 1px white solid;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
`;

const NavChangeFavoriteSport = styled.div`
    color: var(--system-lightgray);
    @media (hover: hover) {
        &:hover {
            color: #fff;
        }
    }
`;

const Title = styled.span`
    margin-right: 0.5rem;
`

const SportContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none;
`;

const Sport = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 5rem;
    height: 6rem;
`;

const SportIcon = styled.div<{ url: string, size: number }>`
    width: ${props => props.size}rem;
    height: ${props => props.size}rem;
    background: ${props => `url(${props.url}) center center / cover`};
`;

const GameSportIcon = styled(SportIcon)`
    transform: translateY(-1rem);
`;

const SportName = styled.div`
    font-size: var(--font-size-7);
    font-weight: 500;
`;

const RecentGameRecordContainer = styled.main`
    height: fit-content;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
`;


export default HomeV2Page;