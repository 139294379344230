import {CircleImg} from "../components/atom/CircleImg";
import { ReactComponent as ArrowWhite } from "../icon/nav_arrow_white.svg";
import { ReactComponent as Check } from "../icon/check.svg";
import styled from "styled-components";
import {useEffect, useState} from "react";
import ImageDataGroup from "../components/molecules/ImageDataGroup";
import {useNavigate} from "react-router-dom";


interface GameStartBottomSheetProps {
    sport: {
        icon: string;
    },
    navigateToPlay: () => void;
}

interface User {
    id: number;
    name: string;
    profileImageUrl: string;
}
interface SelectedUser extends User {
    teamIndex: number;
}

const GameStartBottomSheet = (props: GameStartBottomSheetProps) => {
    const [bottomSheetVisible, setBottomSheetVisible] = useState<boolean>(false);
    const otherUsers: User[] = Array.from(
        { length: 41 },
        (_, i) => ({ id: i, name: "user" + i, profileImageUrl: "/images/user.svg"})
    )
    const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
    const [step, setStep] = useState<number>(1);

    const [searchUserName, setSearchUserName] = useState<string>("");
    const [searchInputVisible, setSearchInputVisible] = useState<"true" | "false">("false");

    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchUserName(e.target.value);
    }


    useEffect(() => {
        if (bottomSheetVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [bottomSheetVisible]);

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);


    return (
        <>
            <FloatingGameStartButton onClick={
                (e) => {
                    e.preventDefault();
                    setBottomSheetVisible(true);
                }
            }>
                <Text>경기 시작하기</Text><ArrowWhite />
            </FloatingGameStartButton>
            <BottomSheet $visible={String(bottomSheetVisible)}>
                <BottomSheetBackGround onClick={
                    (e) => {
                        e.preventDefault();
                        setBottomSheetVisible(false);
                    }
                }/>
                <BottomSheetContent>
                    {step === 1 && (
                        <>
                            <PlayerSelectHeader>
                                <PlayerSelectDescribe>
                                    <SportIcon url={props.sport.icon} size={2}/>
                                    게임 참가자를 선택해 주세요
                                </PlayerSelectDescribe>
                                <NavNextStep
                                    $active={selectedUsers.length > 0 ? "true" : "false"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (selectedUsers.length > 0) {
                                            // 다음 단계로 넘어가기
                                            setStep(2);
                                        }
                                    }}
                                >
                                    <Text>다음</Text><ArrowWhite />
                                </NavNextStep>
                            </PlayerSelectHeader>
                            <SelectedUserContainer>
                                <ImageDataGroup
                                    dataList={selectedUsers.map((i) => ({ id: i.id, url: i.profileImageUrl}))}
                                    size={2.5}
                                    extraTextType={"number"}
                                />
                                <Count>{selectedUsers.length}</Count>

                            </SelectedUserContainer>

                            <DivideLine></DivideLine>
                            <Filters>
                                <Condition>최근 경기한 친구</Condition>
                                <Condition>등록된 친구</Condition>
                            </Filters>
                            <SelectAllButtonContainer>
                                <SearchUserInputContainer $visible={searchInputVisible} >
                                    <SearchInputCloseButton onClick={(e) => {
                                        e.preventDefault();
                                        setSearchUserName("");
                                    }}>X</SearchInputCloseButton>
                                    <SearchUserInput type={"text"}  value={searchUserName} onChange={handleUserNameChange}/>
                                </SearchUserInputContainer>

                                <SearchIcon onClick={(e) => {
                                    e.preventDefault();
                                    setSearchInputVisible(prev => prev === "true" ? "false" : "true");
                                }}> 검색</SearchIcon>
                                <AllButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedUsers(otherUsers.map((i) => ({ ...i, teamIndex: -1 })));
                                    }
                                    }>전체 선택</AllButton>
                                <AllButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedUsers([]);
                                    }
                                    }>전체 해제</AllButton>
                            </SelectAllButtonContainer>
                            <Users>
                                {/* 0 부터 40 까지 */}
                                {otherUsers.map(
                                    (u) => (
                                        <InviteUser
                                            key={`InviteUser-${u.id}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (selectedUsers.find((x) => x.id === u.id)) {
                                                    setSelectedUsers(selectedUsers.filter((x) => x.id !== u.id));
                                                } else {
                                                    setSelectedUsers([...selectedUsers, { ...u, teamIndex: -1 }]);
                                                }
                                            }}
                                        >
                                            <CircleImg url={"/images/user.svg"} width={3} height={3}/>
                                            {u.name}
                                            {
                                                selectedUsers.find((x) => x.id === u.id) ? (
                                                    <Selected selected={"true"}>
                                                        <Check />
                                                    </Selected>
                                                ) : (
                                                    <Selected selected={"false"}></Selected>
                                                )
                                            }

                                        </InviteUser>
                                    )
                                )}
                            </Users>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <PlayerSelectHeader>
                                <NavPrevStep
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (selectedUsers.length > 0) {
                                            setStep(1);
                                        }
                                    }}
                                >
                                    {"이전"}
                                </NavPrevStep>
                                <PlayerSelectDescribe>
                                    <SportIcon url={props.sport.icon} size={2}/>
                                    팀을 구성해주세요
                                </PlayerSelectDescribe>
                                <NavNextStep
                                    $active={selectedUsers.length !== 1 ? "true" : "false"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (selectedUsers.length > 0) {
                                            // 다음 단계로 넘어가기
                                            props.navigateToPlay();

                                            // navigate("/play");
                                        }
                                    }}
                                >
                                    {"경기 시작"}
                                </NavNextStep>
                            </PlayerSelectHeader>
                            <TeamContainer>
                                <Team>
                                    <TeamName>A팀</TeamName>
                                    <TeamPlayers>
                                        <ImageDataGroup
                                            dataList={selectedUsers
                                                .filter(u => u.teamIndex === 0)
                                                .map(u => ({ id: u.id, url: u.profileImageUrl}))

                                            }
                                            size={2}
                                            showLimit={3}
                                            extraTextType={"number"}
                                        />
                                        <TeamCount>{selectedUsers.filter(u => u.teamIndex === 0).length}</TeamCount>
                                    </TeamPlayers>
                                </Team>
                                <Team>
                                    <TeamName>B팀</TeamName>
                                    <TeamPlayers>
                                        <ImageDataGroup
                                            dataList={selectedUsers
                                                .filter(u => u.teamIndex === 1)
                                                .map(u => ({ id: u.id, url: u.profileImageUrl}))

                                            }
                                            size={2}
                                            showLimit={3}
                                            extraTextType={"number"}
                                        />
                                        <TeamCount>{selectedUsers.filter(u => u.teamIndex === 1).length}</TeamCount>
                                    </TeamPlayers>
                                </Team>

                            </TeamContainer>
                            <DivideLine></DivideLine>
                            <SelectedUserList>
                                {selectedUsers.map(user => {
                                    return (
                                        <SelectedUser>
                                            <UserInfo>
                                                <CircleImg url={"/images/user.svg"} width={2.5} height={2.5} color={"var(--system-morelightgray)"}/>
                                                <UserName>{user.name}</UserName>
                                            </UserInfo>

                                            <TeamButtonContainer>
                                                <TeamButton
                                                    $active={user.teamIndex === 0 ? "true" : "false"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setSelectedUsers(selectedUsers.map((u) => {
                                                            if (u.id === user.id) {
                                                                if (user.teamIndex === 0) {
                                                                    return { ...u, teamIndex: -1 };
                                                                } else {
                                                                    return { ...u, teamIndex: 0 };
                                                                }
                                                            }
                                                            return u;
                                                        }));
                                                    }}
                                                >A팀</TeamButton>
                                                <TeamButton
                                                    $active={user.teamIndex === 1 ? "true" : "false"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setSelectedUsers(selectedUsers.map((u) => {
                                                            if (u.id === user.id) {
                                                                if (user.teamIndex === 1) {
                                                                    return {...u, teamIndex: -1};
                                                                } else {
                                                                    return {...u, teamIndex: 1};
                                                                }
                                                            }
                                                            return u;
                                                        }));
                                                    }}
                                                >B팀</TeamButton>
                                            </TeamButtonContainer>
                                        </SelectedUser>
                                    )
                                })
                                }
                            </SelectedUserList>
                        </>
                    )}

                </BottomSheetContent>
            </BottomSheet>
        </>
    )
}



const SearchIcon = styled.button`
    min-width: 3rem;
    height: 2rem;
    border: none;
    `;

const SearchUserInputContainer = styled.div< { $visible : string }>`
    display: flex;
    flex-direction: row;
    width: ${(props) => (props.$visible === "true" ? '5rem' : '0')};
    opacity: ${(props) => (props.$visible === "true" ? '1' : '0')};
    transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
    flex-grow: 1;
`;

const SearchUserInput = styled.input`
    flex-grow: 1;
    height: 2rem;
    border: none;
    border-bottom: 1px solid var(--system-lightgray);
    margin: 0 0.5rem;
    width: 5rem;
    
`;

const SearchInputCloseButton = styled.button`
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    border: none;
    background: inherit;
`;

const Text = styled.span`
    margin-right: 0.5rem;
`;

const TeamButtonContainer = styled.div`
`;

const TeamButton = styled.button<{ $active: string }>`
    width: fit-content;
    min-width: 3rem;
    height: 2rem;
    border-radius: 1rem;
    border: 1px solid ${props => props.$active === "true" ? 'var(--brand-blue)' : 'var(--system-lightgray)'};
    color: ${props => props.$active === "true" ? 'var(--brand-blue)' : 'var(--system-lightgray)'};
    margin-left: 0.2rem;
    background: #fff;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
`;

const SelectedUser = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    justify-content: space-between;
`;

const UserName = styled.div`
    font-size: var(--font-size-6);
    margin-left: 0.5rem;
    flex-grow: 1;
`;


const SelectedUserList = styled.div`
    //background: #92A3B2;
    width: 100%;
    // 일단, 남은 화면을 전부 채우고 싶고
    // 리스트이기 때문에 스크롤할 수 있어야해.
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    scrollbar-width: none;
    
`;


const TeamPlayers = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    height: 3rem;
`;

const TeamCount = styled.div`
    font-size: var(--font-size-4);
    font-weight: bold;
    color: var(--system-deepblack);
    width: 2rem;
`;

const Count = styled.div`
    font-size: var(--font-size-3);
    font-weight: bold;
    color: var(--brand-blue);
    width: 3rem;
`;

const TeamName = styled.div`
    font-size: var(--font-size-6);
    font-weight: bold;
    margin-bottom: 1rem;
`

const Team = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 49%;
    height: 100%;
    background: var(--system-morelightgray);
    border-radius: 1.5rem;
    padding: 1rem 0 0 1rem;
`;

const TeamContainer = styled.div`
    height: 7rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom : 1rem;
`;

const FloatingGameStartButton = styled.div`
    // 화면 하단 가운데에 고정
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 10rem;
    height: 3rem;
    border-radius: 1.5rem;
    background: var(--brand-blue);
    opacity: 0.9;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SelectAllButtonContainer = styled.div`
    height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
`;

const AllButton = styled.button`
    min-width: 5rem;
    border: none;
    height: 2rem;
    border-radius: 1rem;
    margin-left: 0.2rem;
    cursor: pointer;
`;

const SelectedUserContainer = styled.div`
    height: fit-content;
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
`;


const PlayerSelectHeader = styled.div`
    height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem 0;
`;


const NavButton = styled.button`
    cursor: pointer;
    height: 2rem;
    min-width: 4rem;
    width: fit-content;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 1rem;
`;

const NavNextStep = styled(NavButton)<{$active: "true"| "false"}>`
    background: ${props => props.$active === "true" ? 'var(--brand-blue)' : 'var(--system-lightgray)'};
    color: #fff;
`;

const NavPrevStep = styled(NavButton)`
    background: var(--system-morelightgray);
    color: #000;
`;

const PlayerSelectDescribe = styled.div`
    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-7);
    & > * {
        margin-right: 0.5rem;
    }
`;

const Filters = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
    align-content: center;
    margin: 0 0 1rem 0;
`;

const Condition = styled.div`
    width: fit-content;
    height: 2rem;
    border-radius: 1rem;
    background: var(--system-lightgray);
    text-align: center;
    padding: 0 0.5rem;
    line-height: 2rem;
    margin-right: 0.5rem;
    font-size: var(--font-size-7);
    color: #fff;
`;



const Users = styled.div`
    height: 50%;
    flex-grow: 1;
    width: 100%;
    //width: 18rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    cursor: pointer;
    overflow-y: auto;
    scrollbar-width: none;
    position: relative;

    //&::before,
    //&::after {
    //    content: '';
    //    position: fixed;
    //    width: 100%;
    //    //background: rgba(255, 255, 255, 0.99);
    //    height: 10px; /* 그라데이션 높이 */
    //    pointer-events: none; /* 클릭 이벤트 무시 */
    //    z-index: 1; /* 다른 콘텐츠 위에 오도록 설정 */
    //    backdrop-filter: blur(1px); /* blur 효과 추가 */
    //    background: rgba(255, 255, 255, 0.5);
    //}
    //
    //&::before {
    //    bottom: 0;
    //}
`;


const InviteUser = styled.div`
    height: 5rem;
    width: 33%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0.2rem;
    position: relative;
`;


const BottomSheet = styled.div<{ $visible: string }>`
    position: fixed;
    left: 0;
    bottom: ${(props) => (props.$visible === "true" ? '0' : '-100%')};
    width: 100%;
    height: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`;

const BottomSheetBackGround = styled.div`
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    opacity: 0;
`;


const DivideLine = styled.div`
    height: 0;
    width: 100%;
    border: 1px solid var(--system-lightgray);
    margin: 0 0 2rem 0;
    z-index: 10;
`;



const BottomSheetContent = styled.div`
    max-width: var(--max-width);
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    border-radius: 2rem 2rem 0 0;
    color: black;
    overflow: hidden;
    scrollbar-width: none;
    padding: 2rem 1.5rem 0 1.5rem;
`;


const Selected = styled.div<{ selected: string }>`
    position: absolute;
    left: 60%;
    top: 0.4rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.selected === "true" ? 'var(--brand-blue)' : 'var(--system-lightgray)'};
`;

const SportIcon = styled.div<{ url: string, size: number }>`
    width: ${props => props.size}rem;
    height: ${props => props.size}rem;
    background: ${props => `url(${props.url}) center center / cover`};
`;

export default GameStartBottomSheet;