import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {userState} from "../state/userState";
import {DescribedTitle} from "../components/atom/DescribedTitle";
import {UserCard} from "../components/atom/UserCard";
import {convertToGameStatus, IMatch} from "../types/match";
import {fetchMatchesByTeam} from "../api/match";
import {format} from "date-fns";

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const user = useRecoilValue(userState);

    const [isLoading, setIsLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState('페이지 로딩중입니다');

    const [recentMatchRecords, setRecentMatchRecords] = React.useState<IMatch[]>([]);

    useEffect(() => {
        setIsLoading(true);

        if (user) {
            initData().then(() => {
                setIsLoading(false);
            });
        }
    }, [])

    /** 페이지를 그리기 위해 필요한 데이터들 설정 */
    const initData = async () => {
        try {
            const [matches] = await Promise.all([
                fetchMatchesByTeam(1),
                // sleep(1),   // 사용자 경험을 위한 1초 대기
            ])

            setRecentMatchRecords(matches.matches);

        } catch (error) {
            console.log(error);
        }
    }

    const goBasketballRecordPage = () => {
        navigate('/basketball');
    }

    const goBasketballPlayPage = (matchId: number) => {
        navigate(`/match/basketball/${matchId}`);
    }

    const goBasketballSetupPage = () => {
        navigate('/match/basketball/setup');
    }

    const goBadmintonRecordPage = () => {
        navigate('/badminton');
    }

    const handleBasketballNavButtonClicked = () => {
        goBasketballSetupPage()
    }

    const handleBadmintonNavButtonClicked = () => {
        goBadmintonRecordPage()
    }

    const alertForNotImplemented = () => {
        alert('곧 만나요!');
    }

    const convertSportToKorean = (sport: string) => {
        switch (sport.toLowerCase()) {
            case 'basketball':
                return '농구';
            case 'badminton':
                return '배드민턴';
            case 'volleyball':
                return '배구';
            case 'bowling':
                return '볼링';
            case 'tennis':
                return '테니스';
            case 'table_tennis':
                return '탁구';
            default:
                return '기타';
        }
    }

    return (
        <HomePageWrapper>
            {/* 1fr UserCard */}
            <UserCardWrapper>
                {user ? (
                    <UserCard userId={1} name={user.name} />
                ) : (
                    <Empty></Empty>
                )}
            </UserCardWrapper>

            {/* 2fr Navigator buttons to Record Page */}
            <NavWrapper>
                <DescribedTitle
                    title="스포츠 기록지 선택"
                    description={<>{user ? user.name : "나"}의 경기기록 <b>Spo & Matrix</b> 로 관리하세요!</>}
                />
                <ButtonWrapper>
                    <Row>
                        <NavButtonWrapper>
                            <NavButton $locked={false} onClick={handleBasketballNavButtonClicked}>농구</NavButton>
                        </NavButtonWrapper>
                        <NavButtonWrapper>
                            <NavButton $locked={false} onClick={handleBadmintonNavButtonClicked}>배드민턴</NavButton>
                        </NavButtonWrapper>
                        <NavButtonWrapper>
                            <Lock>🔒</Lock>
                            <NavButton $locked={true} onClick={alertForNotImplemented}>배구</NavButton>
                        </NavButtonWrapper>
                    </Row>
                    <Row>
                        <NavButtonWrapper>
                            <Lock>🔒</Lock>
                            <NavButton $locked={true} onClick={alertForNotImplemented}>볼링</NavButton>
                        </NavButtonWrapper>
                        <NavButtonWrapper>
                            <Lock>🔒</Lock>
                            <NavButton $locked={true} onClick={alertForNotImplemented}>테니스</NavButton>
                        </NavButtonWrapper>
                        <NavButtonWrapper>
                            <Lock>🔒</Lock>
                            <NavButton $locked={true} onClick={alertForNotImplemented}>탁구</NavButton>
                        </NavButtonWrapper>
                    </Row>
                </ButtonWrapper>
            </NavWrapper>
            {/* Recent Match Record List for login user*/}
            {user ? (
                <MatchRecordContainer>
                    <MatchRecordTitle>최근 경기 기록</MatchRecordTitle>
                    <MatchRecordListWrapper>
                        {recentMatchRecords.map((record: IMatch, index) => {
                            return (
                                <MatchWrapper
                                    key={`recent-match-${index}`}
                                    onClick={() => goBasketballPlayPage(record.id)}
                                >
                                    <MatchSport>{convertSportToKorean(record.sport)}</MatchSport>
                                    <MatchInfoWrapper>
                                        <MatchTeamInfo>
                                            <MatchTeamName>{record.summary.team[0].teamName}</MatchTeamName>
                                            <MatchTeamScore>{record.summary.team[0].score}</MatchTeamScore>
                                        </MatchTeamInfo>
                                        <MatchInfo>
                                            <Time>{format(record.matchDate, "yyyy.MM.dd HH:mm")}</Time>
                                            <Status>{convertToGameStatus(record.status)}</Status>
                                        </MatchInfo>
                                        <MatchTeamInfo>
                                            <MatchTeamName>{record.summary.team[1].teamName}</MatchTeamName>
                                            <MatchTeamScore>{record.summary.team[1].score}</MatchTeamScore>
                                        </MatchTeamInfo>
                                    </MatchInfoWrapper>
                                </MatchWrapper>
                            );
                        })}
                    </MatchRecordListWrapper>
                </MatchRecordContainer>
            ) : (
                <Empty></Empty>
            )}
        </HomePageWrapper>
    )
}


const MatchRecordContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: min(23rem, 100%);
    margin-top: 1rem;
    height: 50%;
    min-height: 15rem;

    // 화면 너비에 따른 반응형 디자인
    @media (max-width: 500px) {
        width: min(21rem, 100%);
        height: 30%;
    }
`;

const MatchRecordTitle =styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    height: 1.2rem;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
`;

const MatchRecordListWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`;

const MatchWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 5rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    align-items: center;
    padding: 0.5rem;
    box-shadow: -1px 1px 5px 0 rgba(94,94,94,1);
    cursor: pointer;
`;

const MatchSport = styled.div`
    width: 4rem;
    height: 4rem;
    background: #61dafb;
    margin-right: 0.5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
`;

const MatchInfoWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const MatchTeamInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`;

const MatchTeamName = styled.div`
    font-size: 1.2rem;
`;

const MatchTeamScore = styled.div`
    font-size: 2rem;
`;

const MatchInfo = styled.div`
    flex: 1.5;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`;

const Time = styled.div``;

const Status = styled.div`
    flex: 1;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HomePageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 1rem;
`;

const UserCardWrapper = styled.div`
    width: 23rem;
    min-height: 7rem;
    max-height: 7rem;
    // 화면 너비에 따른 반응형 디자인
    @media (max-width: 500px) {
        width: min(21rem, 100%); // 화면이 작을 때 너비 조절
    }
`;

const Empty = styled.div`
    height: 15%;
`;

const NavWrapper = styled.div`
    height: 40%;
    min-height: 21rem;
    width: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: center; // 수평 가운데 정렬
    align-items: center; // 수직 가운데 정렬
    //width: 100%; // 내용에 맞게 너비 조절

    // 화면 너비에 따른 반응형 디자인
    @media (max-width: 500px) {
        width: min(21rem, 100%); // 화면이 작을 때 너비 조절
        height: 30%;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 15rem;
    justify-content: space-between;
    align-items: center; // 수직 가운데 정렬
    //width: 100%; // 내용에 맞게 너비 조절

    // 화면 너비에 따른 반응형 디자인
    @media (max-width: 500px) {
        height: 13.5rem;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50%;
    margin-bottom: 0.25rem;
`;

// 버튼 스타일
const NavButton = styled.button<{$locked: boolean}>`
    font-size: 16px;
    border: none;
    cursor: ${props => props.$locked ? 'not-allowed' : 'pointer'};
    border-radius: 1rem;
    background-color: ${props => props.$locked ? '#003062' : '#007bff'};
    color: white;
    height: 100%;
    width: 100%;
    user-select: none;

    &:hover {
    background-color: ${props => props.$locked ? '#003062' : '#0056b3'};
    }
`;

const NavButtonWrapper = styled.div`
    font-size: 16px;
    border: none;
    border-radius: 1rem;
    color: white;
    height: 100%;
    width: 33%;
    user-select: none;
    position: relative;
    margin: 0 0.125rem;
    // 화면 너비에 따른 반응형 디자인
`;


const Lock = styled.div`
    position: absolute;
    font-size: 2rem;
    // 왼쪽으로 45정도 기울었으면 좋겠어
    transform: rotate(45deg);
    right: -0.75rem;
    top: -0.75rem;

    // 화면 너비에 따른 반응형 디자인
    @media (max-width: 500px) {
        font-size: 1.5rem;
        right: -0.5rem;
        top: -0.5rem;
    }
`;


export default HomePage;