import {IUser} from "./users";
import {isValueInEnum} from "../utils";


// ENUM
export type TLogType =
    'SCORE'
    | 'ASSIST'
    | 'TEAMFOUL'
    | 'CHANGE'
    | 'QUARTER'
    | BasketballGameStatus
    | EBasketballPlayerAction;

export enum MatchStatus {
    READY = 'READY',
    PROGRESS = 'PROGRESS',
    DONE = 'DONE'
}

export enum Sport {
    BADMINTON = 'BADMINTON',
    BASKETBALL = 'BASKETBALL',
    BOWLING = 'BOWLING',
    SOCCER = 'SOCCER',
    TENNIS = 'TENNIS',
}
export enum BasketballGameStatus {
    READY = 'READY',
    START = 'START',
    STOP = 'STOP',
    RESUME = 'RESUME',
    END = 'END',
    TIMEOUT = 'TIMEOUT'
}

export enum EBasketballPlayerAction {
    SCORE = 'SCORE',
    ASSIST = 'ASSIST',
    REBOUND = 'REBOUND',
    STEAL = 'STEAL',
    TURN_OVER = 'TURN_OVER',
    FOUL = 'FOUL',
    EJECTION = 'EJECTION',
    CHANGE = 'CHANGE',
    BLOCK = 'BLOCK',
}

export function convertToGameStatus(status: MatchStatus): string {
    switch (status) {
        case MatchStatus.READY:
            return '시작 전'
        case MatchStatus.PROGRESS:
            return '경기 중'
        case MatchStatus.DONE:
            return '경기 종료'
    }
}

// interface
export interface ICandidatePlayer extends IUser {
    team?: number;
}

export interface IPlayer extends IUser {
    active: boolean;
    number?: number;
    start?: boolean;
    teamIndex?: number;
    ejected?: boolean;
}

export interface ITeam {
    id: number;
    players: IPlayer[];
    teamName: string;
}

export interface BasketballData {
    type: TLogType;
    detail: any,
    quarter: number,
    teamIndex: number,
    player?: IPlayer
}

export interface IRecord {
    id?: number;
    data: BasketballData
    loggedAt: Date,
    userId?: number;
    matchId: number;
}

export enum EMatchBuildStep {
    USER_SELECT='USER_SELECT',
    TEAM_ORGANIZATION='TEAM_ORGANIZATION',
}

export interface IMatch {
    matchDate: Date;
    description: string;
    sport: Sport;
    status: MatchStatus;
    summary: SummaryData;
    team: {id: number};
    id: number;
    teamId: number;
    eventId: number | null;
    createdAt: Date;
    updatedAt: Date;
    tournamentId: number |  null;
}

export interface IMatchAndRecords {
    matchDate: string;
    description: string;
    sport: Sport;
    status: MatchStatus;
    summary: SummaryData;
    team: {id: number};
    id: number;
    teamId: number;
    eventId: number | null;
    createdAt: Date;
    updatedAt: Date;
    tournamentId: number |  null;
    records: IRecord[];
}

// export interface IRecord {
//     id?: number;
//     data: BasketballData;
//     loggedAt: Date;
//     matchId: number;
//     userId?: number;
// }

export interface ICreateMatchRequest {
    status: MatchStatus;
    description?: string;
    matchDate: string;
    sport: Sport;
    teamId: number;
    eventId?: number;
    summary: SummaryData;
}

export enum TEAM_INDEX {
    GAME = -1,
    HOME = 0,
    AWAY = 1
}

export interface ICreateMatchResponse {
    match: IMatchAndRecords;
}

export interface IOneMatchResponse {
    match: IMatchAndRecords;
}

export interface IMatchListResponse {
    matches: IMatch[];
}


export interface IUpdateMatchRequest {
    status: MatchStatus;
    description?: string;
    matchDate: string;
    summary: SummaryData;
    records: IRecord[];
}
export interface IUpdateMatchResponse {
    match: IMatchAndRecords;
}

export interface TeamInfo {
    teamName: string;
    players: IPlayer[];
    score: number;
    extraInfo?: any;
}

export interface BasketballExtraInfo {
    gameStatus: BasketballGameStatus;
    quarter: number;
    currentQuarter?: number;
}

export interface SummaryData {
    team: [TeamInfo, TeamInfo, ...TeamInfo[]];
    extra: BasketballExtraInfo;
}

