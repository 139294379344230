import React from "react";
import styled from "styled-components";
import {BasketballGameStatus, EBasketballPlayerAction, IRecord, IPlayer} from "../../../types/match";
import {
    createBasketballRecord, filterActionRecords,
    getLastBasketballGameStatus, getLastQuarter,
    isActivePlayer,
    isEjectedPlayer, isEndGame, isProgressGame,
    updateOnePlayer
} from "../../../helpers/match";
import {usePlayMatch} from "../../../hook/playMatchHooks";
import {doNothing} from "../../../utils";


interface BasketballPlayerProps {
    username: string;
    teamIndex: number;
}

export const BasketballPlayer = (props: BasketballPlayerProps) => {
    const {
        match,
        setMatch,
        records,
        matchId,
        quarter,
        isLoading,
        addRecords
    } = usePlayMatch();


    const player = match?.summary.team[props.teamIndex].players.find(p => p.username === props.username);

    if (!player) {
        return null;
    }

    const gameStatus = getLastBasketballGameStatus(match?.records ?? []);
    const isPreviousQuarter = quarter < getLastQuarter(match?.records ?? [])
    const handleScoreButtonClick = (score: number) => {
        return () => {
            if (isPreviousQuarter) {
                alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
                return
            }

            const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.SCORE, player, score)
            addRecords(newRecord);
        }
    }

    const handleAssistButtonClick = () => {
        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.ASSIST, player)
        addRecords(newRecord);
    }

    const handleFoulButtonClick = () => {
        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.FOUL, player)
        addRecords(newRecord);
    }


    const handleTurnOverButtonClick = () => {
        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.TURN_OVER, player)
        addRecords(newRecord);
    }

    const handleReboundButtonClick = () => {
        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.REBOUND, player)
        addRecords(newRecord);
    }

    const handleStealButtonClick = () => {
        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.STEAL, player)
        addRecords(newRecord);
    }

    const handleEjectionButtonClick = () => {
        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.EJECTION, player)
        addRecords(newRecord);
    }

    const handleBlockButtonClick = () => {
        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.BLOCK, player)
        addRecords(newRecord);
    }

    const handlePlayerActiveButtonClick = () => {
        if (!match) {
            return
        }

        if (isEjectedPlayer(match.records, player)) {
            return
        }

        if (isPreviousQuarter) {
            alert(`이전 쿼터의 기록은 수정할 수 없습니다. (현재 쿼터: ${quarter}쿼터, 마지막 기록: ${getLastQuarter(match?.records ?? [])}쿼터)`)
            return
        }

        const active = isActivePlayer(match.records, props.teamIndex, player);
        const message: string = active ? 'OUT' : 'IN';
        const newRecord = createBasketballRecord(matchId, props.teamIndex, quarter, EBasketballPlayerAction.CHANGE, player, message)
        addRecords(newRecord)
    }

    const handlePlayerStartingMemberButtonClick = () => {
        const updatedMatch = updateOnePlayer(match!, props.teamIndex, player, {start: !player.start})
        setMatch(updatedMatch)
    }

    const handlePlayerNumberButtonClick = () => {
        let number;
        while (true) {
            number = window.prompt('선수 번호를 입력해주세요 (숫자만 입력해주세요)');
            if (number) {
                if (!isNaN(parseInt(number))) {
                    break;
                }
            }
        }

        const updatedMatch = updateOnePlayer(match!, props.teamIndex, player, {number: parseInt(number)})
        setMatch(updatedMatch)
    }


    const isActive = isActivePlayer(match?.records ?? [], props.teamIndex, player)
    const isEjected = isEjectedPlayer(match?.records ?? [], player)


    const isPlayerActiveButtonActive = gameStatus === BasketballGameStatus.READY ? !!player.start : isActive;
    const isActionButtonDisabled = !isProgressGame(gameStatus) || !isActive;
    const isEjectionButtonDisabled = !isProgressGame(gameStatus) || isEjected;
    const isActiveButtonDisabled = isEndGame(gameStatus) || isEjected;
    const isBackNumberButtonDisabled = isEndGame(gameStatus);

    return (
        <Member>
            <StatusButton
                $disabled={isBackNumberButtonDisabled}
                $active={isPlayerActiveButtonActive}
                onClick={isBackNumberButtonDisabled ? doNothing : handlePlayerNumberButtonClick}
            >
                <BackNumber>{player.number ?? '?'}</BackNumber>
                <Name>{player.name}</Name>
                <Ejected $show={isEjected}></Ejected>
            </StatusButton>

            <MemberActionButtonWrapper $count={6}>
                {gameStatus === BasketballGameStatus.READY ? (
                    <MemberActionButton
                        onClick={handlePlayerStartingMemberButtonClick}
                    >
                        {player.start ? '후보': '선발'}
                    </MemberActionButton>
                ) : (
                    <MemberActionButton
                        disabled={isActiveButtonDisabled}
                        onClick={handlePlayerActiveButtonClick}
                    >
                        {isActive ? 'OUT': 'IN'}
                    </MemberActionButton>
                )}

                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleScoreButtonClick(1)}
                >1점<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.SCORE, player, 1).length})
                </MemberActionButton>
                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleScoreButtonClick(2)}
                >2점<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.SCORE, player, 2).length})
                </MemberActionButton>
                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleScoreButtonClick(3)}
                >3점<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.SCORE, player, 3).length})
                </MemberActionButton>
                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleFoulButtonClick}
                >반칙<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.FOUL, player).length})
                </MemberActionButton>
                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleAssistButtonClick}
                >어시<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.ASSIST, player).length})`
                </MemberActionButton>
                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleReboundButtonClick}
                >리바<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.REBOUND, player).length})
                </MemberActionButton>
                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleStealButtonClick}
                >스틸<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.STEAL, player).length})
                </MemberActionButton>
                <MemberActionButton
                    disabled={isActionButtonDisabled}
                    onClick={handleTurnOverButtonClick}
                >실책<br/>({filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.TURN_OVER, player).length})
                </MemberActionButton>
                <MemberActionButton
                    disabled={isEjectionButtonDisabled}
                    onClick={handleEjectionButtonClick}
                >퇴장
                </MemberActionButton>
                <MemberActionButton
                    disabled={isEjectionButtonDisabled}
                    onClick={handleBlockButtonClick}
                >블락
                </MemberActionButton>

            </MemberActionButtonWrapper>

            <MemberScore>{filterActionRecords(records, props.teamIndex, EBasketballPlayerAction.SCORE, player).length}점</MemberScore>
        </Member>
    )
}

const Member = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0.5rem 0;
    justify-content: center;
    //background: #fdcc84;
`

const BackNumber = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
`;

const Name = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6rem;
    letter-spacing: 0;
 `;

const Ejected = styled.div<{$show: boolean}>`
    width: 1rem;
    height: 1.5rem;
    position: absolute;
    top: -0.5rem;
    left: -0.3rem;
    rotate: 30deg;
    background: red;
    display: ${props => props.$show ? 'block' : 'none'};
`

const StatusButton = styled.div<{$active: boolean, $disabled: boolean}>`
    position: relative;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    border-radius: 1.25rem;
    margin: 0 0.5rem 0 0.5rem;
    cursor: pointer;
    font-size: 0.7rem;
    line-height: 1rem;
    border: none;
    letter-spacing: -0.05rem;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${props => props.$disabled && `
        cursor: not-allowed;
        opacity: 0.5;
    `}
    background: ${props => props.$active ? 'green' : '#FF6B3D'};
    color: white;
`


const MemberActionButtonWrapper = styled.div<{$count: number}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    //max-width: ${props => `${(props.$count || 5) * 2.25}rem`};
    //@media (max-width: 500px) {
    //    > :nth-child(n+6) {
    //        display: none;
    //    }    
    //}
    
`

export const MemberActionButton = styled.button<{disabled?: boolean}>`
    padding: 0;
    width: 1.75rem;
    min-width: 1.75rem;
    height: 2.5rem;
    font-size: 0.65rem;
    font-weight: 600;
    margin: 0 0.5rem 0 0;
    border-radius: 0.5rem;
    ${props => props.disabled && `
        cursor: not-allowed;
    `}
`


const MemberScore = styled.div`
    width: 3rem;
    margin: 0 0 0 0.5rem;
    text-align: left;
    font-size: 0.9rem;
    
    // max-width 가 500px 이하일 때, display: none;
    @media (max-width: 500px) {
        display: none;
    }
`
