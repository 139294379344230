import {
    BasketballGameStatus,
    EBasketballPlayerAction,
    IPlayer,
    IRecord,
    MatchStatus,
    TEAM_INDEX,
    TeamInfo,
    TLogType
} from "../types/match";
import {exhaustiveCheck, isValueInEnum} from "../utils";
import {IPlayMatchModel} from "../serializers/match";


// --------------- 경기기록 생성 함수
/**
 * 경기 기록을 생성하는 함수
 * @param matchId
 * @param teamIndex 0: 홈팀, 1: 어웨이팀, -1: 게임
 * @param quarter
 * @param logType 기록 타입 (ex. SCORE, FOUL, TIMEOUT)
 * @param player 선수 정보
 * @param detail 기록 상세 정보 (ex. 득점 점수, 교체)
 */
export function createBasketballRecord(
    matchId: number,
    teamIndex: number,
    quarter: number,
    logType: TLogType,
    player?: IPlayer,
    detail?: any
): IRecord {

    return {
        data: {
            type: logType,
            detail,
            quarter,
            teamIndex,
            player: player ? {...player} : undefined,
        },
        loggedAt: new Date(),
        matchId,
        userId: player?.id,
    };
}

// --------------- 경기기록 생성 함수 끝


// --------------- 경기기록 기반 정보조회 함수
export type TCurrentGameStatusText = string
export type TGameStatusButtonText = string
export const getGameStatusLabels = (gameStatus: BasketballGameStatus): [TCurrentGameStatusText, TGameStatusButtonText] => {
    switch (gameStatus) {
        case BasketballGameStatus.READY:
            return ['경기 전', '경기 시작'];
        case BasketballGameStatus.START:
            return ['경기 시작', '중지'];
        case BasketballGameStatus.STOP:
            return ['중지', '재개'];
        case BasketballGameStatus.RESUME:
            return ['재개', '중지'];
        case BasketballGameStatus.END:
            return ['경기 종료', '경기 종료'];
        case BasketballGameStatus.TIMEOUT:
            return ['타임아웃', '재개'];
        default:
            exhaustiveCheck(gameStatus);
            return ['never happen', 'never happen'];
    }
}

/** 경기 기록중 개인 기록이 아닌 "경기 시스템 레코드"를 필터링하는 함수 */
export const filterGameRecords = (records: IRecord[], type: TLogType, detail?: any) => {
    if (!records) {
        return [];
    }

    let filteredRecords = records
        .filter(record => record.data.teamIndex === TEAM_INDEX.GAME)
        .filter(record => record.data.type === type);

    if (detail) {
        filteredRecords = filteredRecords.filter(record => record.data.detail === detail);
    }

    return filteredRecords;
}

/** 경기 기록중 "선수 행동 레코드"를 필터링하는 함수 */
export const filterActionRecords = (records: IRecord[], teamIndex: TEAM_INDEX, type: EBasketballPlayerAction, player?: IPlayer, detail?: any) => {
    if (!records) {
        return [];
    }

    let filteredRecords = records
        .filter(record => record.data.teamIndex === teamIndex)
        .filter(record => record.data.type === type)

    if (player) {
        filteredRecords = filteredRecords.filter(record => record.data.player?.username === player.username);
    }

    if (detail) {
        filteredRecords = filteredRecords.filter(record => record.data.detail === detail);
    }

    return filteredRecords;
}

export const getLastQuarter = (_records: IRecord[]) => {
    return _records
        .filter((record: IRecord) => record.data.type === 'QUARTER')
        .reduce((acc, cur) => Math.max(acc, cur.data.quarter), 1);
}


/** 특정 선수의 마지막 상태를 조회하는 함수 */
export const getLastPlayerInfo = (records: IRecord[], teamIndex: number, player: IPlayer): IPlayer => {
    return {
        ...player,
        active: isActivePlayer(records, teamIndex, player),
        ejected: records.some(record => record.data.type === EBasketballPlayerAction.EJECTION && record.data.player?.username === player.username)
    }
}

/** 특정 팀의 점수를 조회하는 함수 */
export const getTeamScore = (records: IRecord[], teamIndex: number): number => {
    return filterActionRecords(records, teamIndex, EBasketballPlayerAction.SCORE)
        .reduce((acc, cur) => acc + cur.data.detail, 0)
}

/** 특정 팀의 특정 쿼터에서의 팀파울을 조회하는 함수 */
export const getFoulCountByTeamAndQuarter = (records: IRecord[], teamIndex: number, quarter: number): IRecord[] => {
    return records
        .filter(record => record.data.teamIndex === teamIndex)
        .filter(record => record.data.type === EBasketballPlayerAction.FOUL)
        .filter(record => record.data.quarter === quarter)
}

/** 게임의 마지막 상태를 조회하는 함수 */
export const getLastBasketballGameStatus = (records: IRecord[]): BasketballGameStatus => {
    const gameStatusRecords =  records
        .filter((record: IRecord) => isValueInEnum(BasketballGameStatus, record.data.type))

    if (gameStatusRecords.length === 0) {
        return BasketballGameStatus.READY;
    } else {
        return gameStatusRecords[0].data.type as BasketballGameStatus;
    }
}

export const getLastMatchStatus = (gameStatus: BasketballGameStatus): MatchStatus => {
    if (gameStatus === BasketballGameStatus.READY) {
        return MatchStatus.READY;
    } else if (gameStatus === BasketballGameStatus.END) {
        return MatchStatus.DONE;
    } else {
        return MatchStatus.PROGRESS;
    }
}


export const isProgressGame = (gameStatus: BasketballGameStatus) => gameStatus !== BasketballGameStatus.READY &&
    gameStatus !== BasketballGameStatus.END &&
    gameStatus !== BasketballGameStatus.TIMEOUT &&
    gameStatus !== BasketballGameStatus.STOP;

export const isEndGame = (gameStatus: BasketballGameStatus) => gameStatus === BasketballGameStatus.END;


// --------------- 경기기록 기반 정보조회 함수 끝


// --------------- 경기 정보 업데이트 함수
/**
 * 로그를 기반으로 경기 요약 정보를 업데이트하는 함수 (순수 함수)
 * @param match
 * @returns IPlayMatchModel
 * @example const updatedMatch = updateMatchSummary(match);
 */
export function applyRecordsToSummary(match: IPlayMatchModel): IPlayMatchModel {
    // update summary by this.records
    const deepCopiedMatch: IPlayMatchModel = JSON.parse(JSON.stringify(match));
    for (let teamIndex = 0; teamIndex < 2; teamIndex++) {
        const team: TeamInfo = deepCopiedMatch.summary.team[teamIndex];
        for (let playerIndex = 0; playerIndex < team.players.length; playerIndex++) {
            const player = team.players[playerIndex];

            // player 의 active, ejected 를 업데이트
            team.players[playerIndex] = getLastPlayerInfo(deepCopiedMatch.records, teamIndex, player)
        }

        // team 의 score 를 업데이트
        deepCopiedMatch.summary.team[teamIndex].score = getTeamScore(deepCopiedMatch.records, teamIndex)
    }

    // gameStatus, quarter 를 업데이트
    deepCopiedMatch.summary.extra = {
        quarter: getLastQuarter(deepCopiedMatch.records),
        gameStatus: getLastBasketballGameStatus(deepCopiedMatch.records),
    }

    deepCopiedMatch.matchDate = new Date(deepCopiedMatch.matchDate);

    return deepCopiedMatch
}

/**
 * 경기 요약 정보중 특정 선수의 정보를 업데이트하는 함수 (순수 함수)
 * player 의 teamName 을 수정할때만 사용
 * @param match
 * @param teamIndex
 * @param updatedData
 * @returns IPlayMatchModel
 * @example const updatedMatch = updateOneTeam(match, teamIndex, {teamName: 'newTeamName'});
 */
export function updateOneTeam(match: IPlayMatchModel, teamIndex: number, updatedData: {teamName?: string}): IPlayMatchModel {
    // update summary by this.records
    const deepCopiedMatch: IPlayMatchModel = JSON.parse(JSON.stringify(match));

    deepCopiedMatch.summary.team[teamIndex] = {
        ...deepCopiedMatch.summary.team[teamIndex],
        score: getTeamScore(deepCopiedMatch.records, teamIndex),
        ...updatedData
    }

    deepCopiedMatch.matchDate = new Date(deepCopiedMatch.matchDate);

    return deepCopiedMatch
}

/**
 * 경기 요약 정보중 특정 선수의 정보를 업데이트하는 함수 (순수 함수)
 * player 의 number, start 를 수정할때만 사용
 * @param match
 * @param teamIndex
 * @param player
 * @param updatedData
 * @returns IPlayMatchModel
 * @example const updatedMatch = updateOnePlayer(match, teamIndex, player, {start: true, number: 99});
 */
export function updateOnePlayer(match: IPlayMatchModel, teamIndex: number, player: IPlayer, updatedData: {start?: boolean, number?: number}): IPlayMatchModel {
    // update summary by this.records
    const deepCopiedMatch: IPlayMatchModel = JSON.parse(JSON.stringify(match));

    const playerIndex = deepCopiedMatch.summary.team[teamIndex].players.findIndex(p => p.username === player.username);
    if (playerIndex === -1) {
        throw new Error('Player not found');
    }

    deepCopiedMatch.summary.team[teamIndex].players[playerIndex] = {
        ...getLastPlayerInfo(deepCopiedMatch.records, teamIndex, player),
        ...updatedData
    }
    deepCopiedMatch.matchDate = new Date(deepCopiedMatch.matchDate);

    return deepCopiedMatch
}

export const resetGame = (match: IPlayMatchModel): IPlayMatchModel => {
    const deepCopiedMatch: IPlayMatchModel = JSON.parse(JSON.stringify(match));

    return {
        ...deepCopiedMatch,
        matchDate: new Date(deepCopiedMatch.matchDate),
        records: [],
        status: MatchStatus.READY,
        summary: {
            extra: {
                gameStatus: BasketballGameStatus.READY,
                quarter: 1
            },
            team: [{
                    ...deepCopiedMatch.summary.team[0],
                    score: 0,
                    teamName: 'A',
                    players: deepCopiedMatch.summary.team[0].players.map(player => {
                        return {
                            ...player,
                            active: false,
                            ejected: false
                        }
                    })
                }, {
                    ...deepCopiedMatch.summary.team[1],
                    score: 0,
                    teamName: 'B',
                    players: deepCopiedMatch.summary.team[1].players.map(player => {
                        return {
                            ...player,
                            active: false,
                            ejected: false
                        }
                    })
                },
            ],
        }
    }
}

// --------------- 경기 정보 업데이트 함수 끝


// --------------- 플레이어 관련 함수

/** 특정 선수가 현재 활동중인지 조회하는 함수 */
export const isActivePlayer = (records: IRecord[], teamIndex: number, player: IPlayer): boolean => {
    const gameStatus = getLastBasketballGameStatus(records)
    if (gameStatus === BasketballGameStatus.READY) {
        return false;
    }

    if (isEjectedPlayer(records, player)) {
        return false;
    }

    const changeRecords = filterActionRecords(records, teamIndex, EBasketballPlayerAction.CHANGE, player);
    if (changeRecords.length === 0) {
        return !!player.start
    }

    const lastChangeRecord = changeRecords[0];
    return lastChangeRecord.data.detail === 'IN';
}

/** 특정 선수가 퇴장당한 상태인지 조회하는 함수 */
export const isEjectedPlayer = (records: IRecord[], player: IPlayer): boolean => {
    return records.some(record => record.data.type === EBasketballPlayerAction.EJECTION && record.data.player?.username === player.username);
}

// --------------- 플레이어 관련 함수 끝