import {useEffect, useState} from "react";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import {userState} from "../../../state/userState";
import {sleep} from "../../../utils";

const OAuthKakaoPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const authorizationCode = new URLSearchParams(window.location.search).get("code");
    const KAKAO_REST_API_KEY = "d6d9191b15629c497272b00af09f3a06";
    const [accessToken, setAccessToken] = useState<string | null>(null);

    const [user, setUser] = useRecoilState(userState);


    useEffect(() => {
        console.log(`Authorization Code: ${authorizationCode}`);
        if (authorizationCode) {
            axios.post("https://kauth.kakao.com/oauth/token", {
                code: authorizationCode,
                grant_type: "authorization_code",
                client_id: KAKAO_REST_API_KEY,
                redirect_uri: `${window.location.origin}/oauth/kakao`,
            }, {
                headers: {
                    "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
                },
            }).then((res) => {
                console.log(JSON.stringify(res));
                setAccessToken(res.data.access_token);
            }).catch((err) => {
                console.log(err);
                alert(`카카오 로그인에 실패했습니다. ${err}`);
                navigate("/login", { replace: true })
            });
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
            console.log(`accessToken: ${accessToken}`);
            axios.get("https://kapi.kakao.com/v2/user/me", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
                },
            }).then((res) => {
                console.log(res.data);
                // alert(JSON.stringify(res.data));
                const isNewUser = true;
                setUser({
                    userId: res.data.id + "",
                    name: res.data.kakao_account.profile.nickname,
                    token: accessToken,
                    profileImageUrl: res.data.kakao_account.profile.profile_image_url,
                    isNewUser
                })

                sleep(0)
                    .then(() => {
                        if (isNewUser) {
                            navigate("/signup", { replace: true });
                        } else {
                            navigate("/", { replace: true });
                        }
                    })

            }).catch((err) => {
                console.log(err);
                alert(`카카오 유저정보 조회에 실패했습니다. ${err}`);
                navigate("/login", { replace: true })
            });
        }
    }, [accessToken]);


    return (
        <></>
    );
}

export default OAuthKakaoPage;