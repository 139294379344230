import React from "react";
import styled from "styled-components";
import {BasketballGameStatus, IRecord, IPlayer, ITeam} from "../../../types/match";


interface BasketballPlayerProps {
    player: IPlayer;
    updatePlayer: (player: IPlayer) => void;
}

export const BasketballMember = (props: BasketballPlayerProps) => {
    const handleEntryOutButtonClick = (player: IPlayer) => {
        const newPlayer: IPlayer = {...player};
        newPlayer.teamIndex = -1;
        props.updatePlayer(newPlayer);
    }

    const handleStartButtonClick = (player: IPlayer) => {
        const newPlayer: IPlayer = {...player};
        newPlayer.start = !newPlayer.start
        props.updatePlayer(newPlayer);
    }

    const handleBackNumberButtonClick = (player: IPlayer) => {
        const newPlayer: IPlayer = {...player};
        const number = prompt('등번호를 입력해주세요');
        if (number) {
            newPlayer.number = parseInt(number);
            props.updatePlayer(newPlayer);
        }
    }


    return (
        <Member>
            <StatusButton
                onClick={() => handleBackNumberButtonClick(props.player)}
                $active={!!props.player.start}
            >
                <BackNumber>{props.player.number ?? '?'}</BackNumber>
                <Name>{props.player.name}</Name>
            </StatusButton>

            <MemberActionButton onClick={() => handleStartButtonClick(props.player)}>
                {props.player.start ? '후보': '선발'}
            </MemberActionButton>
            <MemberEntryOutButton onClick={() => handleEntryOutButtonClick(props.player)}>
                x
            </MemberEntryOutButton>
        </Member>
    )
}

const Member = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0.5rem 0;
    justify-content: center;
    //background: #fdcc84;
`

const BackNumber = styled.div`
    font-size: 1rem;
`;

const Name = styled.div`
    font-size: 0.6rem;
    letter-spacing: 0;
 `;

const StatusButton = styled.div<{$active: boolean}>`
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    border-radius: 1.25rem;
    margin: 0 0.5rem 0 0.5rem;
    cursor: pointer;
    font-size: 0.7rem;
    line-height: 1rem;
    border: none;
    letter-spacing: -0.05rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.$active ? 'green' : '#FF6B3D'};
    color: white;
`

export const MemberActionButton = styled.button`
    padding: 0;
    min-width: 1.75rem;
    height: 2.5rem;
    font-size: 0.65rem;
    font-weight: 600;
    margin: 0 0.5rem 0 0;
    border-radius: 0.5rem;
    cursor: pointer;
`

const MemberEntryOutButton = styled.button`
    min-width: 1.75rem;
    margin: 0 0.5rem 0 0;
    border-radius: 0.5rem;
    color: red;
    background-color: inherit;
    border: none;
    cursor: pointer;
`
