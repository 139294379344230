import React, {createContext, useContext, useEffect, useState} from 'react';
import {IMatchAndRecords, IRecord, IUpdateMatchRequest, MatchStatus, SummaryData} from "../types/match";
import {fetchMatchById, updateMatch} from "../api/match";
import {sleep} from "../utils";
import {applyRecordsToSummary} from "../helpers/match";
import {IPlayMatchModel, serializeUpdateMatchApiRequest, serializeMatchApiResponse} from "../serializers/match";

/** 경기 기록을 관리하는 컨텍스트의 타입 */
interface IPlayMatchContext {

    matchId: number; // 경기 ID
    /** 경기 정보 */
    match: IPlayMatchModel | undefined;
    records: IRecord[],
    setMatch: React.Dispatch<React.SetStateAction<IPlayMatchModel | undefined>>; // 경기 진행 중 정보 업데이트 함수

    /** 현재 쿼터 */
    quarter: number;
    setQuarter: React.Dispatch<React.SetStateAction<number>>;
    /** 경기 기록 CUD 함수 */
    addRecords: (newRecord: IRecord) => void;
    removeRecord: (recordIndex: number) => void;
    updateRecord: (recordIndex: number, updatedRecord: IRecord) => void;

    isLoading: boolean; // 경기 정보를 불러오는 중인지 여부
    isChange: number; // 경기 정보가 변경되었는지 여부
    firstLoad: boolean; // 최초 데이터를 불러오는 중인지 여부
    isError: boolean; // 에러 발생 여부

    saveMatch: () => Promise<void>; // 서버에 경기기록 저장
}

/** 경기 기록을 관리하는 컨텍스트 */
const PlayMatchContext = createContext<IPlayMatchContext | undefined>(undefined);

/**
 * 경기 기록을 관리하는 컨텍스트를 사용할 수 있도록 제공하는 커스텀 훅
 * @param matchId
 * @returns IPlayMatchContext
 * @example const { match, setMatch, addRecords, filterGameRecords, filterActionRecords } = usePlayMatch();
 *
 * match.summary 정보는 경기의 요약 정보를 담고 있습니다.
 * match.records 정보는 경기의 모든 기록을 담고 있습니다.
 * 경기를 진행하고, 세부적인 정보를 표시하는 것은 records 만으로 작업합니다.
 * records 정보만으로 화면을 작업하기에
 * 실제 경기기록을 작업중 저장이 되지 않은 상황에서는 match.summary 정보와 화면정보가 다를 수 있습니다.
 */
export const usePlayMatchProvider = (matchId: number): IPlayMatchContext => {
    const [matchIdState, _] = useState<number>(matchId);
    const [match, setMatch] = useState<IPlayMatchModel | undefined>();
    const [quarter, setQuarter] = useState<number>(1);

    const records = match?.records.sort((a, b) => new Date(b.loggedAt).getTime() - new Date(a.loggedAt).getTime()) ?? [];

    /** 경기기록을 저장 후, 받은 응답으로 match를 업데이트하는 경우, isLoading True 로 활용하기 위함 */
    const [isLoading, setIsLoading] = React.useState(true);

    /** 경기기록이 변경되었는지 여부 */
    const [isChange, setIsChange] = React.useState(0);
    /** 최초 match 를 불러오기 전까지만 true */
    const [firstLoad, setFirstLoad] = React.useState(true);

    /** 에러 발생시 true */
    const [isError, setIsError] = React.useState(false);

    useEffect(() => {
        if (matchIdState !== -1) {
            initData(matchIdState).then();
        }
    }, [matchIdState]);

    useEffect(() => {
        if (!isLoading) {
            return
        }

        // 5초 이내에 데이터를 받아오지 못한 경우, 에러 발생
        setTimeout(() => {
            if (isLoading) {
                setIsLoading(false);
                setIsError(true);
            }
        }, 5000)

    }, [isLoading]);

    useEffect(() => {
        if (isLoading) {
            setIsChange(0);
            setIsLoading(false);
        } else {
            setIsChange(prev => prev + 1)
        }
    }, [match])

    /** 경기 정보를 가져와서 세팅해주는 함수 */
    const initData = async (matchId: number) => {
        try {
            const [_match, _] = await Promise.all([
                fetchMatchById(matchId),
                sleep(1)
            ])
            setMatch(serializeMatchApiResponse(_match.match));
            setIsLoading(false);
            setFirstLoad(false);
        } catch (error) {
            console.log(error);
            setIsError(true);
        }
    }

    /** match.records 를 반영한 새로운 match 를 저장하는 함수 */
    const saveMatch = async () => {
        if (!match) {
            return;
        }

        setIsLoading(true);
        // API 호출
        const updatedMatch = applyRecordsToSummary(match)
        const savedMatch = await updateMatch(matchId, serializeUpdateMatchApiRequest(updatedMatch));

        setMatch(serializeMatchApiResponse(savedMatch.match));
    }

    /** 경기 기록을 추가하는 함수 */
    const addRecords = (newRecord: IRecord) => {
        if (!match) {
            return;
        }

        setMatch({
            ...match,
            records: [newRecord, ...match.records]
        });
    }

    /** 특정 경기 기록을 제거하는 함수 */
    const removeRecord = (recordIndex: number) => {
        if (!match) {
            return;
        }

        const editedRecords = match?.records.filter((_, i) => i !== recordIndex)

        setMatch({
            ...match,
            records: editedRecords
        });
    }

    /** 특정 경기 기록을 업데이트하는 함수 */
    const updateRecord = (recordIndex: number, updatedRecord: IRecord) => {
        if (!match) {
            return;
        }

        const editedRecords = [...match?.records]

        editedRecords[recordIndex] = updatedRecord;

        setMatch({
            ...match,
            records: editedRecords
        });
    }

    return {
        matchId: matchIdState,
        records,
        match, setMatch,
        quarter, setQuarter,
        addRecords, updateRecord, removeRecord,
        isLoading, firstLoad, isError, isChange,
        saveMatch,
    };
};

/** 컨텍스트를 사용할 수 있도록 제공하는 커스텀 훅 */
export const usePlayMatch = () => {
    const context = useContext(PlayMatchContext);

    if (context === undefined) {
        throw new Error('usePlayMatch must be used within a PlayMatchProvider');
    }

    return context;
};

/** 컨텍스트를 제공하는 컴포넌트에 필요한 props */
interface PlayMatchProviderProps {
    children: React.ReactNode; // children을 위한 타입 지정
    matchId: number;
}

/** 컨텍스트를 제공하는 컴포넌트 */
export const PlayMatchProvider: React.FC<PlayMatchProviderProps> = ({ children, matchId }) => {
    const context = usePlayMatchProvider(matchId);

    return (
        <PlayMatchContext.Provider value={context}>
            {children}
        </PlayMatchContext.Provider>
    );
};