import styled from "styled-components";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { ReactComponent as BackArrow } from "../../icon/back_arrow.svg";
import axios from "axios";
import {api} from "../../axiosInstance";
import {useRecoilState, useRecoilValue} from "recoil";
import {userState} from "../../state/userState";

interface Sport {
    id: number;
    name: string;
    icon: string;
}

interface Game {
    id: number;
    // sport 부분 enum 으로 변경
    sport: Sport;
    date: Date;
    // location: string;
    summary: any;
}

interface User {
    userId: string;
    name: string;
    token: string;
    profileImageUrl?: string;
}

const HomeV2Page: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const user = useRecoilValue(userState);

    const KAKAO_REST_API_KEY = "d6d9191b15629c497272b00af09f3a06";
    let redirectUri = `${window.location.origin}/oauth/kakao`;
    // 페이지 1.5초동안에는 화면 정가운데 로고만 나오도록 하고
    // 해당 페이지 내에서 로그인 방식들을 보여주려고 해. 소설로그인이든, 회원가입하러가기등이 보이도록
    const [splash, setSplash] = useState("ON");

    useEffect(() => {
        console.log(user);
        if (!user) {
            setTimeout(() => {
                setSplash("OFF");
            }, 1500);
        } else {
            navigate(-1);
        }
    }, []);


    return (
        <>
            <LogoWrapper splash={splash}>
                <Icon>I'm <Highlight>Game</Highlight></Icon>
            </LogoWrapper>
            <PageLayout splash={splash}>
                <Nav>
                    {/* 이전으로 눌렀을 때, 어디로 가야하는지 */}
                    <IconWrapper onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);

                    }}>
                        <BackArrow />
                    </IconWrapper>
                    <PageDescribe>로그인</PageDescribe>
                    <Empty />

                </Nav>
                <PeopleIcon src={"/images/login-page-people.png"} />
                <ButtonContainer>
                    <SocialLoginButtonContainer>
                        <KakaoLoginButton href={`https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${redirectUri}&response_type=code`}>카카오로 시작하기</KakaoLoginButton>
                        <NaverLoginButton>네이버로 시작하기</NaverLoginButton>
                        <GoogleLoginButton>구글로 시작하기</GoogleLoginButton>
                    </SocialLoginButtonContainer>
                    <IDPasswordLoginButton>ID 로그인</IDPasswordLoginButton>
                    <SignUpNavigator>아직 회원이 아니신가요? <SignUpHighlight>회원가입</SignUpHighlight></SignUpNavigator>
                </ButtonContainer>

            </PageLayout>
        </>
    );
}

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
`;
const SignUpHighlight = styled.span`
    text-decoration: underline;
    color: var(--brand-blue);
`;

const IDPasswordLoginButton = styled.button`
    width: calc(100% - 3rem);
    height: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    border: none;
    background: inherit;
    color: var(--system-gray);
    font-size: var(--font-size-7);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

`;
const SignUpNavigator = styled.div`
`;

const SocialLoginButton = styled.a`
    width: 100%;
    height: 3rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    border: none;
    background: var(--system-black);
    color: white;
    font-size: var(--font-size-6);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
`;

const KakaoLoginButton = styled(SocialLoginButton)`
    &::before {
        content: url("/images/kakao-logo.png");
        width: 3rem;
        height: 3rem;
        scale: 0.5;
    }
`;

const NaverLoginButton = styled(SocialLoginButton)`
    cursor: not-allowed;
    text-decoration: line-through; 
    &::before {
        content: url("/images/naver-logo.png");
        width: 3rem;
        height: 3rem;
        scale: 0.5;
    }`;

const GoogleLoginButton = styled(SocialLoginButton)`
    cursor: not-allowed;
    text-decoration: line-through;
    &::before {
        content: url("/images/google-logo.png");
        width: 3rem;
        height: 3rem;
        scale: 0.5;
    }`;

const SocialLoginButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1.5rem;
`;

const Empty = styled.div`
`;

const PageDescribe = styled.div`
    //margin-bottom: 0.5rem;
    color: white;
    font-size: var(--font-size-5);
    z-index: 15;
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    height: 3rem;
    width: 100%;
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 20;
`;

const PeopleIcon = styled.img`
    width: 13rem;
    height: 13rem;
`;

const PageLayout = styled.div<{ splash: string }>`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 3rem;
    align-items: center;
    transition: opacity 0.7s;
    opacity: ${props => props.splash == "ON" ? 0 : 1};
    
`;

const LogoWrapper = styled.div<{ splash: string }>`
    position: fixed;
    background: var(--system-deepblack);
    transition: opacity 0.7s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: ${props => props.splash == "ON" ? 1 : 0};
    z-index: ${props => props.splash == "ON" ? 100 : -1};
    justify-content: center;
    align-items: center;
`;

const Icon = styled.div`
    font-size: var(--font-size-3);
    color: #fff;
    font-weight: 900;
`;


const Highlight = styled.span`
    color: var(--brand-blue);
`;


export default HomeV2Page;