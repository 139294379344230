import React from "react";
import styled from "styled-components";
import {BasketballGameStatus, EBasketballPlayerAction, IPlayer, IRecord} from "../../../types/match";
import {format} from "date-fns";
import {usePlayMatch} from "../../../hook/playMatchHooks";
import {resetGame} from "../../../helpers/match";
import {exhaustiveCheck} from "../../../utils";

const getSummary = (record: IRecord) => {
    const player: IPlayer = record.data.player ?? {name: '', id: -1, active: false, username: ''};

    switch (record.data.type) {
        // EBasketballPlayerAction
        case EBasketballPlayerAction.SCORE:
            return `${record.data.detail}점`;
        case EBasketballPlayerAction.ASSIST:
            return `어시스트`;
        case EBasketballPlayerAction.CHANGE:
            return `선수 교체 - ${record.data.detail}`;
        case EBasketballPlayerAction.FOUL:
            return `반칙`;
        case EBasketballPlayerAction.REBOUND:
            return `리바운드`;
        case EBasketballPlayerAction.STEAL:
            return `스틸`;
        case EBasketballPlayerAction.TURN_OVER:
            return `실책(턴오버)`;
        case EBasketballPlayerAction.EJECTION:
            return `퇴장`;
        case EBasketballPlayerAction.BLOCK:
            return '블락';
        // BasketballGameStatus
        case BasketballGameStatus.STOP:
            return `게임 중지`;
        case BasketballGameStatus.RESUME:
            return `게임 재개`;
        case BasketballGameStatus.TIMEOUT:
            return `타임아웃`;
        case BasketballGameStatus.START:
            return `게임 시작 - ${record.data.detail}`;
        case BasketballGameStatus.END:
            return `게임 종료 - ${record.data.detail}`;

        case 'QUARTER':
            return `${record.data.quarter}쿼터 끝`;

        default:
            // @ts-ignore
            exhaustiveCheck(record.data.type)
            return 0;

    }
}


export const BasketballRecords = () => {
    const {
        records,
        match,
        setMatch,
        quarter,
        updateRecord,
        removeRecord
    } = usePlayMatch();

    const handleUpdateRecordButtonClick = (index: number) => {
        alert('아직 구현중입니다')
        // updateRecord(index, updatedRecord);
    }


    const handleDeleteRecordButtonClick = (index: number) => {
        removeRecord(index);
    }

    const handleResetAllRecordsButtonClick = () => {
        if (!match) {
            return
        }

        const updatedMatch = resetGame(match);
        setMatch(updatedMatch);
    }

    const handleResetQuarterRecordsButtonClick = () => {
        if (!match) {
            return
        }

        setMatch({
            ...match,
            records: match.records.filter((record) => {
                return record.data.quarter !== quarter
            })
        })
    }

    return (
        <RecordTemplate>
            <OptionButtonTemplate>
                <GameOptionButton onClick={handleResetQuarterRecordsButtonClick}>쿼터 기록 리셋</GameOptionButton>
                <GameOptionButton onClick={handleResetAllRecordsButtonClick}>기록 전체 리셋</GameOptionButton>
            </OptionButtonTemplate>
            <Records>
                {
                    records.map((record, index) => {
                        const key = record.data.teamIndex === -1
                            ? `game-record-${index}}`
                            : `team-${record.data.teamIndex}-${index}`;

                        return (
                            <Record key={key} $team={record.data.teamIndex}>
                                <RecordTime>{format(record.loggedAt, "HH:mm:ss")}</RecordTime>
                                <RecordQuarter>{record.data.quarter}쿼터</RecordQuarter>
                                <RecordPlayerName>{record.data.player?.name}</RecordPlayerName>
                                <RecordSummary>{getSummary(record)}</RecordSummary>
                                <RecordButton onClick={() => handleUpdateRecordButtonClick(index)}>수정</RecordButton>
                                <RecordButton onClick={() => handleDeleteRecordButtonClick(index)}>삭제</RecordButton>
                            </Record>
                        )
                    })
                }
            </Records>
        </RecordTemplate>

    )

}


const RecordTemplate = styled.div`
    min-height: 15rem;
`;

const Records = styled.div`
    width: 100%;
    background: white;
    border: 1px outset black;
    min-height: 15rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: left;
`

const Record = styled.div<{$team: number}>`
    width: 35rem;
    display: flex;
    flex-direction: row;
    background: ${props => props.$team === -1 ? '#b9b5ff' : props.$team === 0 ? '#f6f6f6' : '#ffeeb3'};
    border: 1px solid #e0e0e0;
    margin: 0 0 0 0;
    height: 1.2rem;
`

const RecordTime = styled.div`
    width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* 텍스트를 한 줄로 만듭니다. */
    margin-right: 1rem; 
`

const RecordQuarter = styled.div`
    width: 3rem;
    margin-right: 1rem;
`;

const RecordPlayerName = styled.div`
    text-align: left;
    min-width: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* 텍스트를 한 줄로 만듭니다. */
    margin-right: 1rem;
`

const RecordSummary = styled.div`
    text-align: left;
    min-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* 텍스트를 한 줄로 만듭니다. */
    margin-right: 1rem;
    flex: 1;
`

const RecordButton = styled.button`
    padding: 0;
    margin: 0;
    min-width: 4rem;
    border: none;
    cursor: pointer;
    background: transparent;
`


const OptionButtonTemplate = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 2rem;
`

const GameOptionButton = styled.button`
    width: 7rem;
    height: 2rem;
    margin: 0 0.5rem 1rem 0.5rem;
    border-radius: 0.5rem;;
`