import React from "react";

export const BadmintonScoreBoard = (scoreRecord: any[], totalScore: number, teams: ({
    teamName: string;
    users: string[]
} | {
    teamName: string;
    users: string[]
})[], setTotalScore: (value: (((prevState: number) => number) | number)) => void, setScoreRecord: (value: (((prevState: any[]) => any[]) | any[])) => void, isFinished: boolean) => {
    return <div className={'badminton-score-board-container'}>
        <div className={'badminton-score-board'}>
            {
                scoreRecord.map((teamIndex, score) => {
                    return (
                        <div className={'badminton-score-game-index'} onClick={() => {
                            setTotalScore(score);
                        }}>
                            <p className={score === totalScore ? 'current badminton-score-board-score' : 'badminton-score-board-score'}>{score + 1}</p>
                            {
                                score >= totalScore ? (
                                    teamIndex === -1 ? (
                                        <>
                                            <p className={'badminton-score-board-score'}
                                               key={`score-${score}-0}`}>-</p>
                                            <p className={'badminton-score-board-score'}
                                               key={`score-${score}-1}`}>-</p>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                teams.map((team, index) => {
                                                    return (
                                                        <p className={'badminton-score-board-score removed'}
                                                           key={`score-${score}-${index}`}>{teamIndex === index ? '승' : '패'}</p>
                                                    );
                                                })
                                            }
                                        </>
                                    )
                                ) : (
                                    <>
                                        {
                                            teams.map((team, index) => {
                                                return (
                                                    teamIndex === index ? (
                                                        <p className={'badminton-score-board-score win'}
                                                           key={`score-${score}-${index}`}>{'승'}</p>
                                                    ) : (
                                                        <p className={'badminton-score-board-score lose'}
                                                           key={`score-${score}-${index}`}>{'패'}</p>
                                                    )

                                                );
                                            })
                                        }
                                    </>
                                )
                            }

                        </div>

                    );
                })
            }
        </div>
        <div className={'badminton-score-edit-btn-container'}>
            <button onClick={() => {
                setTotalScore(0);
                setScoreRecord(new Array(25).fill(-1));
            }}>리 셋
            </button>

            <button disabled={totalScore <= 0} onClick={() => {
                if (totalScore > 0) {
                    setTotalScore(prev => prev - 1);
                }
            }}>이 전
            </button>

            <button disabled={scoreRecord[totalScore] === -1} onClick={() => {
                if (scoreRecord[totalScore] !== -1) {
                    setTotalScore(prev => prev + 1);
                }
            }}>되돌리기
            </button>

            <button disabled={scoreRecord[totalScore] === -1} onClick={() => {
                setScoreRecord(prev => {
                    const newScoreRecord = [...prev];
                    for (let i = totalScore; i < newScoreRecord.length; i++) {
                        newScoreRecord[i] = -1;
                    }
                    return newScoreRecord;
                })
            }}>삭 제
            </button>
        </div>
        <div className={'badminton-score-edit-btn-container'}>
            <button disabled={!isFinished} onClick={() => {
                console.log('save')
            }}>저 장
            </button>
        </div>
    </div>;
}
