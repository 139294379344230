import styled from "styled-components";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

interface Sport {
    id: number;
    name: string;
    icon: string;
}

interface Game {
    id: number;
    // sport 부분 enum 으로 변경
    sport: Sport;
    date: Date;
    // location: string;
    summary: any;
}

const GamePlayPage: React.FC = () => {
    const navigate = useNavigate();
    const boardingSteps = [
        {
            imageUrl: "123",
            title: "운동 뭐 좋아해?",
            subtitle: "내가 좋아하는 스포츠 경기를 설정해 보세요",
        },
        {
            imageUrl: "123",
            title: "같이 할 사람 불러!",
            subtitle: "나와 함께 경기 하는 사람들을 초대해 보세요",
        },
        {
            imageUrl: "123",
            title: "게임 끝, 저장!",
            subtitle: "게임 저장을 눌러 경기 결과를 저장하세요",
        },
        {
            imageUrl: "123",
            title: "교류 시작!",
            subtitle: "주변 사람들과 함께 교류해 보세요",
        }
    ];

    const [step, setStep] = useState(0);

    return (
        <>
            <PageLayout>
                {/*<StepWrapper>*/}
                {/*    {*/}
                {/*        boardingSteps.map((step, index) => {*/}
                {/*            return (*/}
                {/*                <Step>*/}
                {/*                    <div> {step.title} </div>*/}
                {/*                    <div> {step.subtitle} </div>*/}
                {/*                    <div> {step.imageUrl} </div>*/}
                {/*                </Step>*/}
                {/*            );*/}
                {/*        })*/}
                {/*    }*/}
                {/*</StepWrapper>*/}
                <StepWrapper>
                    <Step>
                        <OnboardingImage src={`/images/onboarding-${step}.png`} />
                        <Text>
                            <StepTitle> {boardingSteps[step].title} </StepTitle>
                            <StepSubTitle> {boardingSteps[step].subtitle} </StepSubTitle>
                        </Text>
                    </Step>
                    <StepSlide>
                        {
                            boardingSteps.map((_, index) => {
                                return (
                                    <Index selected={index === step ? "true" : "false"}></Index>
                                );
                            })
                        }
                    </StepSlide>
                </StepWrapper>
                <NextButton onClick={(e) => {
                    if (step === 3) {
                        navigate("/", {replace: true});
                    }
                    else {
                        setStep(step + 1);
                    }
                }}>
                    {step === 3 ? "시작하기" : "다음"}
                </NextButton>
            </PageLayout>
        </>
    );
}

const StepTitle = styled.div`
    font-size: var(--font-size-4);
    font-weight: bold;
    margin: 2rem 0 0.5rem 0;
    transition: all 0.3s ease-in-out;
`;

const StepSubTitle = styled.div`
    font-size: var(--font-size-7);
    color: var(--system-morelightgray);
    transition: text-indent 0.3s ease-in-out
    
`;


const Index = styled.div<{selected: string}>`
    height: 0.5rem;
    width: ${props => props.selected === "true" ? "2rem" : "0.5rem"};
    border-radius: 0.25rem;
    transition: width 0.5s;
    background: ${props => props.selected === "true" ? "var(--brand-blue)" : "var(--system-darkblue)"};
`;
const StepSlide = styled.div`
    height: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 5rem;
    max-width: 5rem;
`;


const PageLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 20rem;
    max-width: 20rem;
    overflow-x: hidden;
    align-items: center;
    justify-content: space-between;
    //scroll-snap-type: x mandatory;
    height: 30rem;
    scrollbar-width: none;
    //border: 1px solid #00c346;
`;

const NextButton = styled.button`
    height: 4rem;
    width: calc(100% - 4rem);
    margin: 2rem 0 4rem 0;
    padding: 0;
    border: none;
    background: var(--brand-blue);
    color: white;
    font-size: var(--font-size-5);
    border-radius: 1rem;
    font-weight: bold;
`;

const Step = styled.div`
    height: 25rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease-in-out;
    //border: 1px solid white;
    //scroll-snap-align: start; /* 스크롤 위치 맞춤 */
`;

const OnboardingImage = styled.img`
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    //border: 2px solid #92A3B2;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease-in-out;
    height: 5rem;
`;


export default GamePlayPage;