import {Route, Routes} from "react-router-dom";
import React from "react";
import NotFoundPage from "./page/NotFoundPage";
import {BadmintonRecordPage} from "./page/BadmintonRecordPage";
import HomePage from "./page/HomePage";
import {ProtectedRoute} from "./ProtectedRoute";
import LoginPage from "./page/LoginPage";
import {userState} from "./state/userState";
import {useRecoilValue} from "recoil";
import {SignUpPage} from "./page/SignUpPage";
import {BasketballMatchBuildPage} from "./page/BasketballMatchBuildPage";
import {MatchBasketballPlayPageWrapper} from "./page/MatchBasketballPlayPageWrapper";
import HomeV2Page from "./page/v2/HomeV2Page";
import GamePlayPage from "./page/v2/GamePlayPage";
import GameResultPage from "./page/v2/GameResultPage";
import OnboardingPage from "./page/v2/OnboardingPage";
import LoginV2Page from "./page/v2/LoginV2Page";
import SignUpV2Page from "./page/v2/SignUpV2Page";
import OAuthKakaoPage from "./page/v2/oauth/OAuthKakaoPage";
import UserPage from "./page/v2/UserPage";
import FavoriteSportPage from "./page/v2/FavoriteSportPage";

const BaseRouter = () => {
    const user = useRecoilValue(userState);
    const isAuthenticated = () => !!user

    return (
        <Routes>
            {/* 루트 */}
            <Route path="/legacy" element={<HomePage />} />
            <Route path="/legacy/home" element={<HomePage />} />
            <Route path="/" element={<HomeV2Page />} />

            {/* 로그인 회원가입 */}
            <Route path="/legacy/login" element={<LoginPage />} />
            <Route path="/legacy/signup" element={<SignUpPage />} />

            <Route path="/login" element={<LoginV2Page />} />
            <Route path="/signup" element={<SignUpV2Page />} />

            {/* oauth page */}
            <Route path="/oauth/kakao" element={<OAuthKakaoPage />} />

            {/* 유저 페이지 */}
            <Route path="/user/me" element={<UserPage />} />
            <Route path="/user/:userId" element={<UserPage />} />

            <Route path="/setting/sport/favorite" element={<FavoriteSportPage />} />

            {/* v2 신규 경기 기록 페이지 */}
            <Route path="/play" element={<GamePlayPage />} />
            <Route path="/play/:id/result" element={<GameResultPage />} />

            {/* v2 유저 온보딩 페이지 */}
            <Route path="/onboarding" element={<OnboardingPage />} />

            {/* 없는 페이지 */}
            <Route path="*" element={<NotFoundPage />} />

            {/* 로그인 한경우에만 접근할 수 있는 페이지 */}
            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()}/>}>
                <Route path="/badminton" element={<BadmintonRecordPage selected={true} />} />
                <Route path="/match/basketball/setup" element={<BasketballMatchBuildPage/>} />
                <Route path="/match/basketball/:matchId" element={<MatchBasketballPlayPageWrapper/>} />
            </Route>
        </Routes>
    );
}

export default BaseRouter;