import React, {useEffect} from "react";
import styled from "styled-components";
import {ISelectUser} from "../../../types/users";
import {
    EMatchBuildStep,
    BasketballGameStatus,
    ICreateMatchRequest,
    ICreateMatchResponse,
    IPlayer,
    ITeam, MatchStatus,
    Sport, SummaryData, TeamInfo
} from "../../../types/match";
import {createMatch} from "../../../api/match";
import {CandidatePlayer} from "../../CandidatePlayer";
import {BasketballMember} from "./BasketballMember";


interface BasketballRecordPageProps {
    step: EMatchBuildStep;
    users: ISelectUser[];
    navigateNextStep: (matchId: number) => void;
    navigatePrevStep: () => void;
    selected: boolean;
}


export const BasketballTeamOrganization = (props: BasketballRecordPageProps) => {
    // step 2 - 팀 구성
    const [players, setPlayers] = React.useState<IPlayer[]>([]);

    const [teamOneName, setTeamOneName] = React.useState<string>('A');
    const [teamTwoName, setTeamTwoName] = React.useState<string>('B');

    const [quarter, setQuarter] = React.useState(1);
    const [gameStatus, setGameStatus] = React.useState<BasketballGameStatus>(BasketballGameStatus.READY);
    const [startTime, setStartTime] = React.useState<Date>(new Date());
    const [endTime, setEndTime] = React.useState<Date>(new Date());

    useEffect(() => {
        const _players: IPlayer[] = props.users
            .filter((user: ISelectUser) => user.selected)
            .map((user: ISelectUser): IPlayer => {
                return {...user, active: false, teamIndex: -1}
            });
        setPlayers(_players);
    }, []);

    const selectTeam = (team: number, index: number) => {
        setPlayers(prev => {
            const newPlayers = [...prev];
            newPlayers[index].teamIndex = team;
            return newPlayers;
        });
    }

    const handleSelectTeamButtonClick = (index: number) => {
        selectTeam(0, index);
    }

    const handleTeamTwoButtonClick = (index: number) => {
        selectTeam(1, index);
    }


    const handleNavigateNextStep = async () => {
        const payload: ICreateMatchRequest = {
            status: MatchStatus.READY,
            matchDate: new Date().toISOString(),
            sport: Sport.BASKETBALL,
            teamId: 1,
            summary: {
                team: [
                    {
                        teamName: teamOneName,
                        players: getPlayersByTeam(0),
                        score: 0,
                    },

                    {
                        teamName: teamTwoName,
                        players: getPlayersByTeam(1),
                        score: 0,
                    },
                ],
                extra: {
                    gameStatus: BasketballGameStatus.READY,
                    quarter: 0,
                }
            }
        }
        const {match}: ICreateMatchResponse = await createMatch(payload)


        // save data to server
        // const match = await api.post('/match', {players: players, gameStatus: gameStatus, startTime: startTime, endTime: endTime, quarter: quarter})
        // navigate('basketball/:match.id
        props.navigateNextStep(match.id);
    }

    const handleGameSaveButtonClick = () => {
        // 게임 종료 후 저장 버튼 logs 를 서버에 저장
        alert('구현중입니다')
    };

    const getPlayersByTeam = (team: number): IPlayer[] => {
        return players.filter((player: IPlayer) => player.teamIndex === team);
    }

    const teams: ITeam[] = [{
        id: 0,
        players: getPlayersByTeam(0),
        teamName: teamOneName,
    }, {
        id: 1,
        players: getPlayersByTeam(1),
        teamName: teamTwoName,
    }];

    return (
        <BasketballTemplate style={{display: props.selected ? 'flex' : 'none'}}>
            <NavButtonWrapper>
                <NavButton onClick={props.navigatePrevStep}>이전</NavButton>
                <NavButton disabled={getPlayersByTeam(-1).length > 0} onClick={handleNavigateNextStep}>경기 시작</NavButton>
            </NavButtonWrapper>
            <SportTitle>농구 팀 구성하기</SportTitle>
            {/* 가입된 유저 */}
            {getPlayersByTeam(-1).length > 0 && (<>
                <CandidatePlayerLabel>팀을 선택해주세요</CandidatePlayerLabel>
                <CandidatePlayerDetail>* 모든 플레이어의 팀을 설정해주세요</CandidatePlayerDetail>
                <CandidatePlayersContainer>
                    {players
                        // .filter((player: IPlayer) => player.team === -1)
                        .map((player: IPlayer, index: number) => {
                            return (
                                <CandidatePlayer
                                    key={`candidate-player-${player.username}`}
                                    selected={player.teamIndex !== -1}
                                    user={player}
                                    teamOneClickEventHandler={() => handleSelectTeamButtonClick(index)}
                                    teamTwoClickEventHandler={() => handleTeamTwoButtonClick(index)}
                                    teamOne={teams[0]}
                                    teamTwo={teams[1]}
                                />

                            )
                        })
                    }
                </CandidatePlayersContainer>
            </>)}

            {/* 엔트리 */}
            <TeamTemplate>
                {
                    teams.map((team: ITeam, tIndex: number) => {
                        return (
                            <Team key={`team-${team.id}`}>
                                {/* TODO 팀 이름 누르면, 바꿀수 있도록 */}
                                <TeamNameWrapper onClick={() => {
                                    const name = window.prompt('팀 이름을 입력해주세요\n ("이기자" 라고 입력하시면 "이기자팀" 으로 표시됩니다)');
                                    if (name) {
                                        if (tIndex === 0) {
                                            setTeamOneName(name);
                                        } else {
                                            setTeamTwoName(name);
                                        }
                                    }
                                }}>
                                    <p>{team.teamName} 팀</p>
                                    <EditIcon>✎</EditIcon>
                                </TeamNameWrapper>
                                <TeamPlayerContainer>
                                    {
                                        team.players.map((player: IPlayer, uIndex) => {
                                            return (
                                                <BasketballMember
                                                    key={`player-${player.username}`}
                                                    player={player}
                                                    updatePlayer={(_player: IPlayer) => {
                                                        setPlayers(prev => {
                                                            const newPlayers = [...prev];
                                                            const playerIndex = newPlayers.findIndex((p: IPlayer) => p.username === player.username);
                                                            newPlayers[playerIndex] = _player;
                                                            return newPlayers;
                                                        });
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </TeamPlayerContainer>
                            </Team>
                        );
                    })
                }
            </TeamTemplate>


        </BasketballTemplate>

    );
}


const BasketballTemplate = styled.div`
    padding: 0 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top;
    overflow-y: hidden;
`;

const CandidatePlayersContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    margin-bottom: 1rem;
    min-height: 5rem;
`

const CandidatePlayerLabel = styled.div`
    width: 100%;
    text-align: left;
    margin: 1rem 0 0 0;
    font-size: 1.5rem;
    font-weight: bold;
`

const CandidatePlayerDetail = styled.div`
    width: 100%;
    text-align: left;
    margin: 0.5rem 0;
    font-size: 0.8rem;
    color: red;
`


const TeamTemplate = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const Team = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
`

const TeamPlayerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
`

const TeamNameWrapper = styled.div`
    width: 90%;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.5rem;
    box-shadow: -1px 1px 5px 0 rgba(94, 94, 94, 1);
    position: relative;
`
const EditIcon = styled.span`
    position: absolute;
    right: 0.5rem;
    top: 0;
    font-size: 1rem;
    color: black;
    rotate: 100deg;
    cursor: pointer;
`;

const SportTitle = styled.h1`
    width: 100%;

`

const NavButtonWrapper = styled.div`
    height: 3rem;
    line-height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 1rem 0 0 0;
`;

const Button = styled.button`
    height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid black;
    cursor: pointer;;`

const NavButton = styled(Button)`
    min-width: 6rem;

    &:disabled {
        cursor: not-allowed;
        border: 1px solid #ccc;
    }
`
