import styled from "styled-components";


interface ImageData {
    id: number;
    url: string;
}

interface ImageDataGroupProps {
    dataList: ImageData[];
    showLimit?: number;
    size: number;
    extraTextType: "dot" | "number";
}


const ImageDataGroup: React.FC<ImageDataGroupProps> = ({ dataList, showLimit, size, extraTextType }: ImageDataGroupProps) => {
    const limit = showLimit ? showLimit : 6;
    const etcText = extraTextType === "dot" ? "..." : `+${dataList.length - limit}`;
    return (
        <SelectedDataContainer size={size}>
            <SelectedDataList>
                {
                    dataList.slice(0, limit).map((data: ImageData, i: number) => {
                        return <SelectedData key={data.id} i={i} url={data.url} size={size}>
                            {i == limit - 1 && dataList.length > limit && <Etc>{etcText}</Etc>}
                        </SelectedData>
                    })
                }
            </SelectedDataList>
        </SelectedDataContainer>
    )
}


const SelectedDataContainer = styled.div<{size: number}>`
    height: ${(props) => props.size}rem;
    width: 100%;
    margin-bottom: 1rem;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 50;
`;

const SelectedDataList = styled.div`
    width: 70%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 50;
`;

const SelectedData = styled.div<{ i : number, url: string, size: number }>`
    position: absolute;
    left: 0;
    transform: translateX(${(props) => props.i * (props.size / 2)}rem);
    z-index: ${(props) => 50 + props.i * 10};
    width: ${(props) => props.size}rem;
    height: ${(props) => props.size}rem;    
    border-radius: 50%;
    border: 1px solid #fff;
    background: url(${(props) => props.url}) center center / cover
`;


const Etc = styled.div`
    width: 3rem;
    position: absolute;
    right: -3.5rem;
    bottom: 0;
    z-index: 50;
`;

// const Count = styled.div<{color: string}>`
//     width: fit-content;
//     height: 2.5rem;
//     line-height: 2.5rem;
//     font-size: var(--font-size-3);
//     color: ${(props) => `${props.color}`};
//     font-weight: 900;
//     margin-right: 0.5rem;
//     z-index: 50;
// `;

export default ImageDataGroup;