import styled from "styled-components";
import React from "react";


interface InputProps {
    label: string;
    placeholder?: string;
    type: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUpDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    warning: boolean;
    warningMessage?: string;
    warningCondition?: boolean;
}
export const LabeledInput: React.FC<InputProps> = (props) => {
    const [inputType, setInputType] = React.useState(props.type);
    const [passwordHidden, setPasswordHidden] = React.useState(true);

    const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return (
        <InputWrapper>
            <Label htmlFor={uuid}>{props.label}</Label>
            <Input
                id={uuid}
                name={uuid}
                type={inputType}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                onKeyDown={props.onKeyDown}
                onKeyUp={props.onKeyUpDown}
                onClick={props.onClick}
            />
            {props.type === 'password' && (
                <Icon
                    draggable={false}
                    onClick={() => {
                        if (passwordHidden) {
                            setInputType('text')
                            setPasswordHidden(false);
                        } else {
                            setInputType('password')
                            setPasswordHidden(true);
                        }
                    }}
                > {passwordHidden ? '🔓️' : '🔒'}
                </Icon>
            )}

            {props.warning && props.warningCondition && (
                <WarningMessage>{props.warningMessage}</WarningMessage>
            )}
        </InputWrapper>
    )
}
;

const InputWrapper = styled.form`
    display: flex;
    width: 100%;
    position: relative;
    height: 5.5rem;
    //border: 1px solid black;
    //border-radius: 0.5rem
`;

const Fixed = styled.label`
    position: absolute;
    z-index: 1;
    height: 1rem;
    font-size: 0.9rem;
`

const Label = styled.label`
    position: absolute;
    z-index: 1;
    height: 1rem;
    font-size: 0.9rem;
    top: 0.5rem;
    left: 0.5rem;
    color: gray;
`;

const Input = styled.input`
    flex-direction: column;
    height: 4rem;
    width: 100%;
    font-size: 1.2rem;
    padding-top: 1rem; /* 텍스트를 아래로 이동시킵니다 */
    padding-left:0.5rem; 
    text-align: left;
    border-radius: 0.5rem;
`;

const WarningMessage = styled(Fixed)`
    height: 1.5rem;
    z-index: 2;
    bottom: -0rem;
    left: 0.5rem;
    color: red;
    font-size: 0.9rem;
    line-height: 1.5rem;
`;

const Icon = styled.i`
    position: absolute;
    right: 1rem;
    z-index: 10;
    width: 1rem;
    top: 1.5rem;
    user-select: none;
    cursor: pointer;
`;