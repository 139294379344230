import styled from "styled-components";
import { ReactComponent as UserIcon } from "../../icon/user.svg";
import { ReactComponent as BackArrow } from "../../icon/back_arrow.svg";
import { ReactComponent as Plus } from "../../icon/plus.svg";
import { ReactComponent as Minus } from "../../icon/minus.svg";
import {useState} from "react";
import { ReactComponent as Versus } from "../../icon/versus.svg";
import ImageDataGroup from "../../components/molecules/ImageDataGroup";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userState} from "../../state/userState";


const GamePlayPage: React.FC = () => {
    const user = useRecoilValue(userState);

    const [teamOneScore, setTeamOneScore] = useState<number>(0);
    const [teamTwoScore, setTeamTwoScore] = useState<number>(0);

    const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>, team: number) => {
        let value = e.target.value;
        if (value === "") {
            value = "0";
        }

        if (team === 0) {
            setTeamOneScore(parseInt(value));
        } else {
            setTeamTwoScore(parseInt(value));
        }

    }

    const navigate = useNavigate();

    return (
        <>
            <PageLayout>
                <Nav>
                    <IconWrapper onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);

                    }}>
                        <BackArrow />
                    </IconWrapper>

                    <IconWrapper onClick={(e) => {
                        e.preventDefault();
                        if (user) {
                            navigate("/user/me");
                        } else {
                            navigate("/login");
                        }
                    }}>
                        <UserIcon />
                    </IconWrapper>

                </Nav>
                <Header>
                    <SportIcon url={"/images/sport-icon-badminton.png"} size={5}/>
                    <Describe>경기 기록하기</Describe>
                    <SubDescribe>방장님! 최종 점수를 등록해 주세요</SubDescribe>
                </Header>

                <TeamContainer>
                    <VersusWrapper>
                        <Versus />
                        <NarrowPurple id={"np"}/>
                        <NarrowRed id={"nr"}/>
                        <WidePurple id={"wp"}/>
                        <WideRed id={"wr"}/>
                    </VersusWrapper>


                    <Team>
                        <TeamName>A팀</TeamName>
                        <TeamPlayerList>
                            <ImageDataGroup dataList={[
                                { url: '/images/user.svg', id: 2 },
                                { url: '/images/user.svg', id: 2 },
                                { url: '/images/user.svg', id: 2 },
                            ]} size={2} extraTextType={"number"} showLimit={2}
                            />
                        </TeamPlayerList>

                        <ScoreButtonContainer>
                            <ScoreButton onClick={(e) => {
                                e.preventDefault();
                                setTeamOneScore(prev => Math.max(0, prev - 1));
                            }}><Minus /></ScoreButton>
                            <Score type={"number"} value={teamOneScore} onChange={(e) => handleScoreChange(e, 0)}/>
                            <ScoreButton onClick={(e) => {
                                e.preventDefault();
                                setTeamOneScore(prev => prev + 1);
                            }}><Plus /></ScoreButton>
                        </ScoreButtonContainer>
                    </Team>
                    <Team>
                        <TeamName>B팀</TeamName>
                        <TeamPlayerList>
                            <ImageDataGroup dataList={[
                                { url: '/images/user.svg', id: 2 },
                                { url: '/images/user.svg', id: 2 },
                                { url: '/images/user.svg', id: 2 },
                            ]} size={2} extraTextType={"number"} showLimit={2}
                            />
                        </TeamPlayerList>

                        <ScoreButtonContainer>
                            <ScoreButton onClick={(e) => {
                                e.preventDefault();
                                setTeamTwoScore(prev => Math.max(0, prev - 1));
                            }}><Minus /></ScoreButton>
                            <Score type={"number"} value={teamTwoScore} onChange={(e) => handleScoreChange(e, 1)}/>
                            <ScoreButton onClick={(e) => {
                                e.preventDefault();
                                setTeamTwoScore(prev => prev + 1);
                            }}><Plus /></ScoreButton>
                        </ScoreButtonContainer>
                    </Team>
                </TeamContainer>


                <GameStatusButtonContainer>
                    <GameEndButton onClick={(e) => {
                        e.preventDefault();
                        navigate("/play/3/result", {replace: true});
                    }}>기록 등록 완료</GameEndButton>
                    <GameSkipButton>나중에 등록하기</GameSkipButton>
                </GameStatusButtonContainer>
            </PageLayout>
        </>
    );
}

const PageLayout = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;
const TeamPlayerList = styled.div`
    width: 100%;
    height: 2rem;
    padding: 0 0.5rem;
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    height: 3rem;
    width: 100%;
`;

const GameStatusButtonContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    height: 30%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
`;

const GameEndButton = styled.button`
    width: 100%;
    height: 3rem;
    color: white;
    background: var(--brand-blue);
    border-radius: 1rem;
    border: none;
    margin-bottom: 1rem;
    font-size: var(--font-size-6);
    cursor: pointer;
`;

const GameSkipButton = styled.div`
    text-align: center;
    font-size: var(--font-size-7);
    text-decoration: underline;
    color: var(--system-lightgray);
    cursor: pointer;
    height: 2rem;
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 20;
`;

const Header = styled.header`
    padding: 0 1.5rem;
    //border: 1px white solid;
    width: 100%;
    height: 10rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 15;
`;

const SportIcon = styled.div<{ url: string, size: number }>`
    width: ${props => props.size}rem;
    height: ${props => props.size}rem;
    background: ${props => `url(${props.url}) center center / cover`};
    z-index: 15;
`;

const Describe = styled.div`
    margin-bottom: 0.5rem;
    color: white;
    font-size: var(--font-size-4);
    z-index: 15;
`;

const SubDescribe = styled.div`
    color: var(--system-lightgray);
    font-size: var(--font-size-7);
    z-index: 15;
`;

const TeamContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10rem;
    justify-content: space-around;
    align-items: center;
    position: relative;
    padding: 0 1.5rem;
`;

const VersusWrapper = styled.div`
    //overflow-x: hidden;
    position: absolute;
    left: 0;
    top: -4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;

    z-index: 10;
`;


const WidePurple = styled.div`
    width: 17rem;
    height: 17rem;
    background: radial-gradient(circle, rgba(200, 44, 255, 0.1) 100%, rgba(200, 44, 255, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(200, 44, 255, 0.1); /* 블러 효과 */
    position: absolute;
    top: -8rem;
    //bottom: 0;
    left: -2rem;
    z-index: -1;
`;

const NarrowPurple = styled.div`
    width: 8rem;
    height: 8rem;
    background: radial-gradient(circle, rgba(200, 44, 255, 0.15) 100%, rgba(200, 44, 255, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(200, 44, 255, 0.15); /* 블러 효과 */
    position: absolute;
    z-index: -1;
    left: 5rem;
    top: -1rem;
    
`;

const WideRed = styled.div`
    width: 17rem;
    height: 17rem;
    background: radial-gradient(circle, rgba(244, 0, 29, 0.1) 100%, rgba(244, 0, 29, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(244, 0, 29, 0.1); /* 블러 효과 */
    position: absolute;
    right: 1rem;
    top: -5rem;
    z-index: -1;
`;

const NarrowRed = styled.div`
    width: 8rem;
    height: 8rem;
    background: radial-gradient(circle, rgba(244, 0, 29, 0.15) 100%, rgba(244, 0, 29, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(244, 0, 29, 0.15); /* 블러 효과 */
    position: absolute;
    top: 1rem;
    right: 6rem;
    z-index: -1;
`;

const Team = styled.div`
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

const TeamName = styled.div`
    width: 100%;
    height: 1rem;
    text-align: center;
`;

const ScoreButtonContainer = styled.div`
    min-width: 9rem;
    max-width: 9rem;
    height: 3rem;
    border-radius: 1.5rem;
    border: 1px solid var(--system-darkblue);
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ScoreButton = styled.button`
    min-width: 2rem;
    max-width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: none;
    background: var(--system-darkblue);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: var(--font-size-4);
    cursor: pointer;
    z-index: 50;
`;

const Score = styled.input`
    z-index: 50;
    font-size: var(--font-size-5);
    flex-grow: 1;
    text-align: center;
    background: none;
    min-width: 1rem;
    color: white;
    border: none;
`;

export default GamePlayPage;