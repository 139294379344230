import {IMatchAndRecords, IUpdateMatchRequest,} from "../types/match";


// IMatchAndRecords 에서 matchDate 를 제외한 모든 필드를 가지는 타입
type OmitMatchDate = Omit<IMatchAndRecords, 'matchDate'>;

// OmitMatchDate 를 확장하여 matchDate 의 타입을 Date 로 변경
export interface IPlayMatchModel extends OmitMatchDate {
    matchDate: Date;
}

export function serializeMatchApiResponse(match: IMatchAndRecords): IPlayMatchModel {
    return {
        ...match,
        matchDate: new Date(match.matchDate)
    }
}

export function serializeUpdateMatchApiRequest({ status, description, matchDate, summary, records}: IPlayMatchModel): IUpdateMatchRequest {
    return { status, description, summary, records, matchDate: matchDate.toISOString() }
}



