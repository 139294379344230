import React from "react";
import styled from "styled-components";
import {Property} from "csstype";

interface DescribeTitleProps {
    title: string;
    description: any;
    align?: Property.AlignItems;
    textAlign?: Property.TextAlign;
}

export const DescribedTitle: React.FC<DescribeTitleProps> = (props: DescribeTitleProps) => {
    const align = props.align ? props.align : 'center';
    const textAlign = props.textAlign ? props.textAlign : 'center';

    return (
        <TitleWrapper>
            <Title style={{alignItems: align, textAlign: textAlign}}>{props.title}</Title>
            <Description style={{alignItems: align, textAlign: textAlign}}>{props.description}</Description>
        </TitleWrapper>
    )
}

const TitleWrapper = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 4rem;
`;

const Title = styled.div`
    font-size: 2rem;
    width: 100%;
`;

const Description = styled.div`
    font-size: 0.9rem;
    width: 100%;
`;

