import React from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const NotFound: React.FC = () => {
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1);
    }

    return (
        <NotFoundMessageContainer>
            <NotFoundMessage>해당하는 페이지가 없습니다.</NotFoundMessage>
            <NavigateButton onClick={goBack}>Go Back</NavigateButton>
        </NotFoundMessageContainer>
    );
}

const NotFoundMessageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #61dafb;
`

const NotFoundMessage = styled.div`
    font-size: 24px;
`

const NavigateButton = styled.button`
`

export default NotFound;