// components/ProtectedRoute.tsx
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    const location = useLocation();

    if (!isAuthenticated) {
        // state를 통해 로그인 페이지에 추가 정보를 전달합니다.
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <Outlet />;
};