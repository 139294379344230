import React from "react";

export const BadmintonTeamSpec = (teams: ({ teamName: string; users: string[] } | {
    teamName: string;
    users: string[]
})[], isFinished: boolean, scoreRecord: any[], totalScore: number, setTotalScore: (value: (((prevState: number) => number) | number)) => void, setScoreRecord: (value: (((prevState: any[]) => any[]) | any[])) => void, teamScore: number[]) => {
    return <div className={'badminton-team-container'}>
        {
            teams.map((team, index) => {
                return (
                    <div className={'badminton-team'} key={index}>
                        <div className={'badminton-team-name'}>{team.teamName}</div>
                        {
                            team.users.map((user, index) => {
                                return (
                                    <div className={'badminton-team-user'} key={index}>{user}</div>
                                );
                            })
                        }
                        <div className={'badminton-team-score-btn-wrapper'}>
                            <button
                                disabled={isFinished}
                                className={'badminton-team-score-btn'}
                                onClick={() => {
                                    const newScoreRecord = [...scoreRecord];
                                    newScoreRecord[totalScore] = index;
                                    setTotalScore(totalScore + 1);
                                    setScoreRecord(newScoreRecord);
                                }}
                            >{`득점 (${teamScore[index]})`}</button>
                        </div>
                        {
                            isFinished && (
                                <p className={'badminton-team-win-status'}
                                   key={index}>{teamScore[index] > teamScore[1 - index] ? '승리' : '패배'}</p>
                            )
                        }
                    </div>
                );
            })
        }
    </div>;
}