export const sleep = (seconds: number): Promise<void> => {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

export function exhaustiveCheck(action: never) {
    console.error('Unhandled action', action);
}

export function isValueInEnum<T>(e: T, value: unknown): boolean {
    // @ts-ignore
    return Object.values(e).includes(value);
}

export const doNothing = () => {}