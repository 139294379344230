import { atom } from 'recoil';
import {api} from "../axiosInstance";

export interface User {
    userId: string;
    name: string;
    token: string;
    profileImageUrl?: string;
    isNewUser?: boolean;
}

export const userState = atom<User | null>({
    key: 'userState',
    default: null,
    effects_UNSTABLE: [
        ({setSelf, onSet}) => {
            const savedUser = localStorage.getItem('usr');
            if (savedUser) {
                setSelf(JSON.parse(savedUser));
            }

            onSet((newValue) => {
                if (newValue === null) {
                    localStorage.removeItem('usr');
                } else {
                    localStorage.setItem('usr', JSON.stringify(newValue));
                }
            })
        }
    ]
});