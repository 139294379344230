import styled from "styled-components";
import {CircleImg} from "../../components/atom/CircleImg";
import { ReactComponent as Arrow } from "../../icon/nav_arrow_gray.svg";
import { ReactComponent as UserIcon } from "../../icon/user.svg";
import { ReactComponent as BackArrow } from "../../icon/back_arrow.svg";
import {useEffect, useState} from "react";
import GameStartBottomSheet from "../../modal/GameStartBottomSheet";
import { ReactComponent as Versus } from "../../icon/versus.svg";
import ImageDataGroup from "../../components/molecules/ImageDataGroup";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userState} from "../../state/userState";

interface Sport {
    id: number;
    name: string;
    icon: string;
}

interface Game {
    id: number;
    // sport 부분 enum 으로 변경
    sport: Sport;
    date: Date;
    // location: string;
    summary: any;
}

const GameResultPage: React.FC = () => {
    const user = useRecoilValue(userState);

    const [teamOneScore, setTeamOneScore] = useState<number>(0);
    const [teamTwoScore, setTeamTwoScore] = useState<number>(0);

    const navigate = useNavigate();

    return (
        <>
            <Nav>
                <IconWrapper onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }}>

                    <BackArrow />
                </IconWrapper>
                <PageDescribe>경기 리뷰</PageDescribe>
                <IconWrapper onClick={(e) => {
                    e.preventDefault();
                    if (user) {
                        navigate("/user/me");
                    } else {
                        navigate("/login");
                    }
                }}>
                    <UserIcon />
                </IconWrapper>


            </Nav>


            <TeamContainer>
                <VersusWrapper>
                    <Versus />
                    <NarrowPurple />
                    <NarrowRed />
                    <WidePurple />
                    <WideRed />

                </VersusWrapper>

                <Team>
                    <TeamName>A팀</TeamName>
                    <TeamPlayerList>
                        <ImageDataGroup dataList={[
                            { url: '/images/user.svg', id: 2 },
                            { url: '/images/user.svg', id: 2 },
                            { url: '/images/user.svg', id: 2 },
                        ]} size={2} extraTextType={"number"} showLimit={2}
                        />
                    </TeamPlayerList>

                    <ScoreButtonContainer>
                        <Score>{teamOneScore}</Score>
                    </ScoreButtonContainer>
                </Team>
                <Team>
                    <TeamName>A팀</TeamName>
                    <TeamPlayerList>
                        <ImageDataGroup dataList={[
                            { url: '/images/user.svg', id: 2 },
                            { url: '/images/user.svg', id: 2 },
                            { url: '/images/user.svg', id: 2 },
                        ]} size={2} extraTextType={"number"} showLimit={2}
                        />
                    </TeamPlayerList>

                    <ScoreButtonContainer>
                        <Score>{teamTwoScore}</Score>
                    </ScoreButtonContainer>
                </Team>
            </TeamContainer>

            <ReplyCount>댓글 수: 10</ReplyCount>
            <ReplyList>
                {
                    Array.from({length: 10}).map((_, index) => {
                        return (
                            <Reply key={index}>
                                <CircleImg url={"/images/user.svg"} width={2.5} height={2.5} />
                                <Text>
                                    <ReplyInfo>
                                        <UserName>유저 이름</UserName>
                                        <WrittenAt>2024.06.30</WrittenAt>
                                    </ReplyInfo>
                                    <ReplyContents>댓글 내용 {index}</ReplyContents>
                                </Text>
                            </Reply>
                        );
                    })
                }
            </ReplyList>
            <UserReplyInputContainer>
                <UserReplyInput placeholder={"댓글을 입력하세요"} />
                <ReplyAddButton>등록</ReplyAddButton>
            </UserReplyInputContainer>
        </>
    );
}

const UserReplyInput = styled.input`
    width: 100%;
    height: 3rem;
    flex-grow: 1;
    border: none;
    border-radius: 1rem;
    padding-left: 1rem;
    margin-right: 1rem;
    font-size: var(--font-size-6);
`;

const ReplyAddButton = styled.button`
    width: 5rem;
    height: 3rem;
    background: var(--brand-blue);
    color: white;
    border: none;
    font-size: var(--font-size-6);
    cursor: pointer;
    border-radius: 0.5rem;
`;

const UserReplyInputContainer = styled.div`
    height: 4rem;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: var(--system-darkblue);
    border-top: 1px solid var(--system-deepblack);
    padding: 1rem;
`;

const Reply = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--system-darkblue);
    padding: 1.5rem 1.5rem 0 1.5rem;
`;

const Text = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1rem;
`;

const ReplyInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
`;

const UserName = styled.div`
    font-size: var(--font-size-7);
    color: white;
`;

const WrittenAt = styled.div`
    font-size: var(--font-size-8);
    color: white;
`;

const ReplyContents = styled.div`
    color: white;
    font-size: var(--font-size-6);
    margin-bottom: 0.5rem;
`;

const ReplyCount = styled.div`
    padding: 0 1.5rem;
    height: 2rem;
    margin-bottom: 0.5rem;
`;

const ReplyList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    //padding-bottom: 4rem;  
`;


const TeamPlayerList = styled.div`
    width: 100%;
    height: 2rem;
    padding: 0 0.5rem;
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    height: 3rem;
    margin-bottom: 4rem;
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 20;
`;


const PageDescribe = styled.div`
    //margin-bottom: 0.5rem;
    color: white;
    font-size: var(--font-size-5);
    z-index: 15;
`;


const TeamContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10rem;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    margin: 2rem 0;
`;

const VersusWrapper = styled.div`
    position: absolute;
    left: 0;
    top: -4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;

    z-index: 10;
`;


const WidePurple = styled.div`
    width: 17rem;
    height: 17rem;
    background: radial-gradient(circle, rgba(200, 44, 255, 0.1) 100%, rgba(200, 44, 255, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(200, 44, 255, 0.1); /* 블러 효과 */
    position: absolute;
    top: -8rem;
    //bottom: 0;
    left: -2rem;
    z-index: -1;
`;

const NarrowPurple = styled.div`
    width: 8rem;
    height: 8rem;
    background: radial-gradient(circle, rgba(200, 44, 255, 0.15) 100%, rgba(200, 44, 255, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(200, 44, 255, 0.15); /* 블러 효과 */
    position: absolute;
    z-index: -1;
    left: 5rem;
    top: -1rem;

`;

const WideRed = styled.div`
    width: 17rem;
    height: 17rem;
    background: radial-gradient(circle, rgba(244, 0, 29, 0.1) 100%, rgba(244, 0, 29, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(244, 0, 29, 0.1); /* 블러 효과 */
    position: absolute;
    right: 1rem;
    top: -5rem;
    z-index: -1;
`;

const NarrowRed = styled.div`
    width: 8rem;
    height: 8rem;
    background: radial-gradient(circle, rgba(244, 0, 29, 0.15) 100%, rgba(244, 0, 29, 0) 70%);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px 20px rgba(244, 0, 29, 0.15); /* 블러 효과 */
    position: absolute;
    top: 1rem;
    right: 6rem;
    z-index: -1;
`;

const Team = styled.div`
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

const TeamName = styled.div`
    width: 100%;
    height: 1rem;
    text-align: center;
`;

const ScoreButtonContainer = styled.div`
    width: 8rem;
    height: 3rem;
    border-radius: 1.5rem;
    border: 1px solid var(--system-darkblue);
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

const Score = styled.div`
    z-index: 50;
    font-size: var(--font-size-3);
    flex-grow: 1;
    text-align: center;
    background: none;
    width: 1rem;
    color: white;
    border: none;
`;

export default GameResultPage;