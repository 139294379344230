import styled from "styled-components";

export interface UserCardProps {
    userId: number;
    name: string;
    // imageUrl: string;
    // barthDay: Date;
    // location: string;
}

export const UserCard: React.FC<UserCardProps> = (user: UserCardProps) => {
    return (
        <UserCardWrapper>
            <UserImage alt={"profile-image"}/>
            <UserInfoWrapper>
                <UserName>{user.name}</UserName>
                {/*<UserInfo>{user.userId}</UserInfo>*/}
                <UserInfo>위치 (아직 입력받지 않는 값)</UserInfo>
                <UserInfo>생일 (아직 입력받지 않는 값)</UserInfo>
            </UserInfoWrapper>
        </UserCardWrapper>
    )
}

const UserCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: -1px 1px 5px 0 rgba(94,94,94,1);
`;

const UserImage = styled.img`
    background: #61dafb;
    height: 100%;
    display:none;
    width: 25%;
    flex: 3;
`;

const UserInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 75%;
    padding-left: 0.5rem;
`;

const UserName = styled.div`
    font-size: 2rem;
    font-weight: bold;
    flex: 4;
    //padding-top: 1rem;
    width: 100%;
    display: flex;
    /*justify-content: flex-start;
    align-items: center;*/
    text-align: center;
`;

const UserInfo = styled.div`
    flex: 1;
`;