import {api} from "../axiosInstance";
import {IUsersResponse} from "../types/users";

export const fetchUsers = async (): Promise<IUsersResponse> => {
    try {
        const response = await api.get<IUsersResponse>('/users')
        return response.data;
    } catch (error) {
        throw error;
    }
}