import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import { ReactComponent as BackArrow } from "../../icon/back_arrow.svg";
import {useEffect, useState} from "react";

export interface FavoriteSport {
    id: number;
    name: string;
    icon: string;
}

interface SelectableSport extends FavoriteSport {
    selected: boolean;
}

const FavoriteSportPage: React.FC = () => {
    const navigate = useNavigate();

    const [sports, setSports] = useState<SelectableSport[]>([
        {id: 1, name: "축구", selected: false, icon: "/images/sport-icon-soccer.png"},
        {id: 2, name: "야구", selected: false, icon: "/images/sport-icon-baseball.png"},
        {id: 3, name: "농구", selected: false, icon: "/images/sport-icon-basketball.png"},
        {id: 8, name: "배드민턴", selected: false, icon: "/images/sport-icon-badminton.png"},
        {id: 4, name: "배구", selected: false, icon: "/images/sport-icon-volleyball.png"},
        {id: 5, name: "테니스", selected: false, icon: "/images/sport-icon-tennis.png"},
        {id: 6, name: "탁구", selected: false, icon: "/images/sport-icon-pingpong.png"},
        {id: 7, name: "볼링", selected: false, icon: "/images/sport-icon-bowling.png"},
    ]);

    useEffect(() => {
        const temp = localStorage.getItem("fav-spo");
        if (temp !== null) {
            const favoriteSports = JSON.parse(temp) as FavoriteSport[];
            const newSports = sports.map((sport) => {
                const favSport = favoriteSports.find((favSport) => favSport.id === sport.id);
                if (favSport) {
                    sport.selected = true;
                }
                return sport;
            });

            setSports(newSports);
        }
    }, []);

    return (
        <>
            <PageLayout>
                <Nav>
                    <IconWrapper onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}>

                        <BackArrow />
                    </IconWrapper>
                    <PageTitle>나의 관심 스포츠</PageTitle>
                    <Empty />
                </Nav>
                <PageDescribe>
                    나의 관심 스포츠를 선택해 보세요! 축구부터 테니스까지 다양한 스포츠로 즐거운 운동과 건강을 동시에 챙기세요
                </PageDescribe>
                <SportContainer>
                    {sports.map((sport) => {
                        return (
                            <SportButton
                                $checked={sport.selected ? "ON": "OFF"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    sport.selected = !sport.selected;
                                    setSports([...sports]);
                                }}
                            >{sport.name}</SportButton>
                        )
                    })}
                </SportContainer>
                <ButtonContainer>
                    <CancelButton onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}>취소</CancelButton>
                    <SaveButton onClick={(e) => {
                        e.preventDefault();
                        localStorage.setItem("fav-spo", JSON.stringify(
                            sports
                                .filter((sport) => sport.selected)
                                .map((sport) => ({id: sport.id, name: sport.name, icon: sport.icon}))
                        ));
                        navigate("/", {replace: true});
                    }}>선택완료</SaveButton>
                </ButtonContainer>
            </PageLayout>
        </>
    )
}

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 1.5rem;
    margin: 1.5rem 0;
`;


const Button = styled.button`
    width: 5rem;
    height: 3rem;
    flex-grow: 1;
    border-radius: 1rem;
    margin: 0 0.5rem;
    border: none;
`;

const CancelButton = styled(Button)`
    background: var(--system-black);
    color: var(--system-gray);
`;

const SaveButton = styled(Button)`
    background: var(--brand-blue);
    color: white;
`;

const PageLayout = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

const PageDescribe = styled.div`
    color: white;
    font-size: var(--font-size-7);
    padding: 0 1.5rem;
    text-align: left;
    margin: 1rem 0;
    height: 3rem;
`;

const SportContainer = styled.div`
    height: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start;
    overflow-y: auto;
`;


const SportButton = styled.button<{ $checked: string }>`
    width: fit-content;
    height: 3rem;
    background: ${props => props.$checked == "ON" ? "var(--brand-blue)" : "var(--system-deepblack)"};
    color: ${props => props.$checked == "ON" ? "white" : "var(--brand-blue)"};
    border-radius: 1.5rem;
    margin-top: 1rem;
    display: flex;
    border: 1px solid var(--brand-blue);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    font-size: var(--font-size-7);
`;

const Empty = styled.div`
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    height: 3rem;
    width: 100%;
`;

const PageTitle = styled.div`
    //margin-bottom: 0.5rem;
    color: white;
    font-size: var(--font-size-5);
    z-index: 15;
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 20;
`;



export default FavoriteSportPage;