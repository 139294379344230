import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import './App.css';
import BaseRouter from "./routes";
import Layout from "./components/layout/Layout";

const App: React.FC = () => (
    <Router>
        <Layout>
            <BaseRouter />
        </Layout>
    </Router>
);




export default App;
