import React, {useEffect} from "react";
import styled from "styled-components";
import {BasketballPlayer} from "../components/match/basketball/BasketballPlayer";
import {BasketballRecords} from "../components/match/basketball/BasketballRecords";
import {Modal} from "../modal/Modal";
import {ConfirmForm} from "../modal/ConfirmForm";
import {BasketballGameStatus, EBasketballPlayerAction, IPlayer, IRecord, ITeam, MatchStatus} from "../types/match";
import {doNothing, exhaustiveCheck} from "../utils";
import {useNavigate} from "react-router-dom";
import {usePlayMatch} from "../hook/playMatchHooks";
import {
    createBasketballRecord, getFoulCountByTeamAndQuarter, getGameStatusLabels,
    getLastBasketballGameStatus,
    getLastQuarter, getTeamScore,
    isActivePlayer,
    isEjectedPlayer, resetGame, TGameStatusButtonText,
    updateOnePlayer, updateOneTeam
} from "../helpers/match";

const AUTO_SAVE_COUNTDOWN = 5;


export const MatchBasketballPlayPage = () => {
    // path parameter 로 받아온 matchId 를 사용하여 해당 경기의 정보를 가져온다
    const navigate = useNavigate();

    const {
        match,
        setMatch,
        records,
        matchId,
        quarter,
        setQuarter,
        isLoading,
        isChange,
        isError,
        firstLoad,
        saveMatch,
        addRecords
    } = usePlayMatch();

    const teamOnePlayers: IPlayer[] = match?.summary.team[0].players ?? [];
    const teamTwoPlayers: IPlayer[] = match?.summary.team[1].players ?? [];

    const teamOneName = match?.summary.team[0].teamName ?? 'A';
    const teamTwoName = match?.summary.team[1].teamName ?? 'B';

    const gameStatus = getLastBasketballGameStatus(match?.records ?? []);

    const [confirmModalOn, setConfirmModalOn] = React.useState(false);
    const [confirmMessage, setConfirmMessage] = React.useState('');
    const [confirmAction, setConfirmAction] = React.useState<() => void>(() => () => {});

    const [systemMessage, setSystemMessage] = React.useState('경기 데이터를 불러오는 중입니다');

    // autoSaveTimer 는 화면 랜더링에 관련있으므로 useState
    // countdownInterval 은 랜더링과는 관련없으므로 useRef
    const countdownInterval = React.useRef<NodeJS.Timer | null>(null);
    const [countdown, setCountdown] = React.useState(10); // 10초 카운트다운

    // 카운트다운 및 자동 저장 로직을 관리하는 useEffect
    useEffect(() => {
        if (!isChange) {
            // data 를 조회, 혹은 수정 후 데이터반영할때에는 동작하지 않도록 처리
            return;
        }

        // 변경사항이 있을 때만 타이머 리셋
        if (countdownInterval.current) {
            clearInterval(countdownInterval.current);
            countdownInterval.current = null;
        }
        setCountdown(AUTO_SAVE_COUNTDOWN);

        countdownInterval.current = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown - 1 <= 0) {
                    if (countdownInterval.current) clearInterval(countdownInterval.current); // 타이머 정지
                    saveMatch().then(() => clearAutoSaveTimer());
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => {
            if (countdownInterval.current) clearInterval(countdownInterval.current);
        };
    }, [isChange]);

    const clearAutoSaveTimer = () => {
        if (countdownInterval.current) {
            clearInterval(countdownInterval.current);
            setCountdown(AUTO_SAVE_COUNTDOWN);
        }
        clearInterval(countdownInterval.current!);
    }

    const changeTeamName = (teamIndex: number) => {
        if (!match) {
            return
        }

        const name = window.prompt('팀 이름을 입력해주세요\n ("이기자" 라고 입력하시면 "이기자팀" 으로 표시됩니다)');
        if (name) {
            const updated = updateOneTeam(match, teamIndex, {teamName: name});
            setMatch(updated)
        }
    }

    const handleQuarterPrevButtonClick = () => {
        if (quarter > 1) {
            setQuarter(prev => Math.max(1, prev - 1));
        }
    }

    const handleQuarterNextButtonClick = () => {
        if (quarter > getLastQuarter(records)) {
            addRecords(createBasketballRecord(matchId, -1, quarter, 'QUARTER'))
        }
        setQuarter(prev => prev + 1)
    }


    const startGame = (message: string) => {
        const newRecord = createBasketballRecord(matchId, -1, quarter, BasketballGameStatus.START, undefined, message)
        addRecords(newRecord);
    }

    const checkConditionAndGameStart = () => {
        // 인원 수에 대해서 team0ne.length 대 teamTwo.length 경기 시작합니다. 라는 내용을 detail 에 저장해야함
        const activeTeamOnePlayerCounts = teamOnePlayers.filter((p) => p.start).length;
        const activeTeamTwoPlayerCounts = teamTwoPlayers.filter((p) => p.start).length;

        if (activeTeamOnePlayerCounts === 0 && activeTeamTwoPlayerCounts === 0) {
            alert('선수가 없습니다')
            return
        }

        const message = `${activeTeamOnePlayerCounts} 대 ${activeTeamTwoPlayerCounts} 경기`
        if (activeTeamOnePlayerCounts !== activeTeamTwoPlayerCounts) {
            setConfirmMessage('선수 인원이 다른데, 그대로 시작하시겠습니까?')
            setConfirmModalOn(true)
            setConfirmAction(() => () => startGame(message))
        } else {
            startGame(message);
        }
    }

    const endGame = () => {
        const teamOneScore = getTeamScore(records, 0);
        const teamTwoScore = getTeamScore(records, 1);

        const winnerTeam = teamOneScore > teamTwoScore ? 0 : teamOneScore < teamTwoScore ? 1 : null;
        const winnerTeamName = winnerTeam === 0 ? teamOneName : winnerTeam === 1 ? teamTwoName : null;

        const message = winnerTeam !== null
            ? `${winnerTeamName}팀 승리 (${teamOneScore}: ${teamTwoScore})`
            : '무승부'
        const newRecord = createBasketballRecord(matchId, -1, quarter, BasketballGameStatus.END, undefined, message)
        addRecords(newRecord);
    }

    const handleGameStatusButtonClick = () => {
        if (gameStatus === BasketballGameStatus.READY) {
            checkConditionAndGameStart();
        } else if (gameStatus === BasketballGameStatus.START) {
            const newRecord = createBasketballRecord(matchId, -1, quarter, BasketballGameStatus.STOP)
            addRecords(newRecord);
        } else if (gameStatus === BasketballGameStatus.STOP) {
            const newRecord = createBasketballRecord(matchId, -1, quarter, BasketballGameStatus.RESUME)
            addRecords(newRecord);
        } else if (gameStatus === BasketballGameStatus.RESUME) {
            const newRecord = createBasketballRecord(matchId, -1, quarter, BasketballGameStatus.STOP)
            addRecords(newRecord);
        } else if (gameStatus === BasketballGameStatus.END) {
            endGame();
        } else if (gameStatus === BasketballGameStatus.TIMEOUT) {

        } else {
            // never happen
            exhaustiveCheck(gameStatus)
        }
    };

    const handleSaveButtonClick = async () => {
        // 게임 종료 후 저장 버튼 records 를 서버에 저장
        const res = await saveMatch();
    };

    const reset = () => {
        if (!match) {
            return
        }

        const updatedMatch = resetGame(match);
        setMatch(updatedMatch);
    }

    const handleResetButtonClick = () => {
        if (gameStatus === BasketballGameStatus.READY) {
            return
        }
        setConfirmMessage('게임을 초기화하시겠습니까?')
        setConfirmModalOn(true)
        setConfirmAction(() => reset)
    };

    if (firstLoad) {
        return (<LoadingPage>{systemMessage}</LoadingPage>)
    }

    const teams = [{
        id: 0,
        players: teamOnePlayers,
        teamName: teamOneName,
    }, {
        id: 1,
        players: teamTwoPlayers,
        teamName: teamTwoName,
    }] as ITeam[];


    return (
        <BasketballTemplate>
            {isLoading && (<SystemMessage>{systemMessage}</SystemMessage>)}
            <SportTitle>농구 기록지</SportTitle>

            {/* 쿼터 표시 */}
            <QuarterTemplate>
                <QuarterButton key={'prev-quarter-button'} onClick={handleQuarterPrevButtonClick}>이전</QuarterButton>
                <Quarter>{quarter}쿼터</Quarter>
                <QuarterButton key={'next-quarter-button'} onClick={handleQuarterNextButtonClick}>다음</QuarterButton>
            </QuarterTemplate>

            {/* 엔트리 */}
            <TeamTemplate>
                {
                    teams.map((team: ITeam) => {
                        return (
                            <Team key={`team-${team.id}`}>
                                {/* TODO 팀 이름 누르면, 바꿀수 있도록 */}
                                <TeamNameWrapper onClick={() => changeTeamName(team.id)}>
                                    <p>{team.teamName} 팀</p>
                                    <EditIcon>✎</EditIcon>
                                </TeamNameWrapper>
                                <Score>{getTeamScore(records, team.id)}점</Score>
                                <TeamFoulCount>팀파울 : {getFoulCountByTeamAndQuarter(records, team.id, quarter).length}개</TeamFoulCount>
                                {
                                    team.players.map((player: IPlayer, uIndex) => {
                                        return (
                                            <BasketballPlayer
                                                key={`team-${team.id}-player-${uIndex}`}
                                                username={player.username}
                                                teamIndex={team.id}
                                            />
                                        )
                                    })
                                }
                            </Team>
                        );
                    })
                }
            </TeamTemplate>

            {/* 게임 상태 변경 버튼 리스트 */}
            <OptionButtonTemplate>
                {/* 한 버튼으로 게임 시작, 게임 중지, 게임 재게 를 표시 gameStatus 값을 바꿔가며 해당값에 따라 보여지는 텍스트 변경 records 에도 내용 반영 */}
                {gameStatus === BasketballGameStatus.END ? (
                    <GameOptionButton disabled={true}>-</GameOptionButton>
                ) : (
                    <GameOptionButton onClick={handleGameStatusButtonClick}>{getGameStatusLabels(gameStatus)[1]}</GameOptionButton>
                )}

                <GameOptionButton
                    disabled={gameStatus === BasketballGameStatus.READY || gameStatus === BasketballGameStatus.END}
                    onClick={handleGameStatusButtonClick}
                >게임 종료
                </GameOptionButton>
                <GameOptionButton
                    disabled={!isChange}
                    // disabled={gameStatus !== BasketballGameStatus.END}
                    onClick={handleSaveButtonClick}
                >저장{isChange ? `(${countdown}초)` : ''}</GameOptionButton>
                <GameOptionButton
                    disabled={gameStatus === BasketballGameStatus.READY}
                    onClick={handleResetButtonClick}
                >초기화</GameOptionButton>
            </OptionButtonTemplate>

            {/* 농구 기록 타임라인 */}
            <BasketballRecords />
            <Modal
                showModal={confirmModalOn}
                closeModal={() => {
                    setConfirmModalOn(false)
                }}
                children={
                    <ConfirmForm
                        message={confirmMessage}
                        action={confirmAction}
                        close={() => {
                            setConfirmModalOn(false)
                        }}
                    />
                }
            />
        </BasketballTemplate>
    );
}


const BasketballTemplate = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    //background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/images/basketball.png');
    background-size: cover;
    background-position: top;
    position: relative;
`

const CandidatePlayersContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    margin-bottom: 1rem;
`

const CandidatePlayerLabel = styled.div`
    width: 100%;
    text-align: left;
    margin: 1rem 0;
    font-size: 1.5rem;
    font-weight: bold;
`


const TeamTemplate = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    //> :nth-child(1) {
    //    border: 1px solid white;
    //    border-radius: 1rem;
    //}
    //
    //> :nth-child(2) {
    //    border: 1px solid white;
    //    border-radius: 1rem;
    //}

    //@media (max-width: 54rem) {
    //    flex-direction: column;
    //    justify-content: center;
    //    align-items: center;
    //    > :nth-child(1) {
    //        margin-bottom: 1rem;
    //        width: 100%;
    //    }
    //
    //    > :nth-child(2) {
    //        margin-bottom: 1rem;
    //        width: 100%;
    //    }
    //}
`

const Team = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
`

const TeamNameWrapper = styled.div`
    width: 90%;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.5rem;
    box-shadow: -1px 1px 5px 0 rgba(94, 94, 94, 1);
    position: relative;
`
const EditIcon = styled.span`
    position: absolute;
    right: 0.5rem;
    top: 0;
    font-size: 1rem;
    color: black;
    rotate: 100deg;
    cursor: pointer;
`;

const Score = styled.div`
    width: 100%;
    font-size: 2rem;
    text-align: center;
    margin: 0 0 1rem 0
`

const TeamFoulCount = styled.div`
    width: 100%;
    font-size: 1rem;
    text-align: center;
    margin: 0 0 1rem 0
`

const AnonymousMemberActionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 0.5rem 0;
    //background: #fdcc84;
`

const MemberAddContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MemberAddButton = styled.button`
    width: 100%;
    text-align: center;
    height: 2rem;
    border-radius: 1rem;
`

/*
const MemberActionButton = styled.button`
    width: 4rem;
    margin: 0 0.5rem 0 0;
    border-radius: 0.5rem;
`
*/

const MemberScore = styled.div`
    width: 4rem;
    margin: 0 0.5rem 0 0;
`

const OptionButtonTemplate = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`

const GameOptionButton = styled.button`
    width: 5rem;
    margin: 0 0.5rem 1rem 0.5rem;
    border-radius: 0.5rem;;
`

const QuarterTemplate = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`

const QuarterButton = styled.button`
    width: 3rem;
    margin: 0 0.5rem 1rem 0.5rem;
    border-radius: 0.5rem;;
`

const Quarter = styled.div`
    width: 3rem;
    text-align: center;
`

const SystemMessage = styled.div`
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
    background: rgba(255, 255, 255, 0.5);
`

const SportTitle = styled.h1`
    width: 100%;

`

const LoadingPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 1rem;
`;
