import React from "react";
import styled from "styled-components";

export const Modal = ({ showModal, closeModal, children }: {showModal: boolean, closeModal: () => void, children: any}) => {
    if (!showModal) return null;

    return (
        <ModalBackDrop onClick={closeModal}>
            <Contents onClick={e => e.stopPropagation()}>
                <CloseButton onClick={closeModal}>x</CloseButton>
                {children}
            </Contents>

        </ModalBackDrop>
    );
};


const ModalBackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0;
    
    & button {
        cursor: pointer;
    }
`

const Contents = styled.div`
    background-color: white;
    padding: 1.4rem;
    border-radius: 5px;
    /* 모달 크기 조정 */
    width: 500px;
    max-width: 80%;
    position: absolute;
`

const CloseButton = styled.button`
    width: 1.4rem;
    border-radius: 0.7rem;
    border: 1px solid black;
    font-weight: lighter;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    
    left: calc(100% - 1.4rem);
    bottom: 0.7rem;
`
