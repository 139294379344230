import { useParams } from 'react-router-dom';
import {PlayMatchProvider} from "../hook/playMatchHooks";
import React from "react";
import {MatchBasketballPlayPage} from "./MatchBasketballPlayPage";

export const MatchBasketballPlayPageWrapper = () => {
    const params = useParams();

    const matchId = params.matchId ? parseInt(params.matchId as string): -1;

    return (
        <PlayMatchProvider matchId={matchId}>
            <MatchBasketballPlayPage />
        </PlayMatchProvider>
    );
};