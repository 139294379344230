import styled from "styled-components";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { ReactComponent as BackArrow } from "../../icon/back_arrow.svg";
import axios from "axios";
import {api} from "../../axiosInstance";
import {useRecoilState, useRecoilValue} from "recoil";
import {userState} from "../../state/userState";

interface Sport {
    id: number;
    name: string;
    icon: string;
}

interface Game {
    id: number;
    // sport 부분 enum 으로 변경
    sport: Sport;
    date: Date;
    // location: string;
    summary: any;
}

interface User {
    userId: string;
    name: string;
    token: string;
    profileImageUrl?: string;
}

const SignUpV2Page: React.FC = () => {
    const navigate = useNavigate();
    const KAKAO_REST_API_KEY = "d6d9191b15629c497272b00af09f3a06";
    const [accessToken, setAccessToken] = useState<string | null>(null);

    const user = useRecoilValue(userState);

    // 페이지 1.5초동안에는 화면 정가운데 로고만 나오도록 하고
    // 해당 페이지 내에서 로그인 방식들을 보여주려고 해. 소설로그인이든, 회원가입하러가기등이 보이도록
    const [splash, setSplash] = useState("ON");

    useEffect(() => {
        setTimeout(() => {
            setSplash("OFF");
        }, 1500);
    }, []);

    return (
        <>
            <LogoWrapper splash={splash}>
                <Icon>I'm <Highlight>Game</Highlight></Icon>
            </LogoWrapper>
            <PageLayout splash={splash}>
                <Nav>
                    {/* 이전으로 눌렀을 때, 어디로 가야하는지 */}
                    <Empty />
                    <PageDescribe>가입하기</PageDescribe>
                    <Empty />

                </Nav>
                <IntroContainer>
                    <PeopleIcon src={"/images/login-page-people.png"} />
                    <Title>회원가입 완료!</Title>
                    <IntroMessage>{user?.name}님 회원가입이 정상적으로 완료 되었습니다.<br/>즐겨하는 스포츠를 세팅해 보세요</IntroMessage>
                </IntroContainer>

                <ButtonContainer>
                    <LaterButton onClick={() => {
                        navigate("/", {replace: true});
                    }}>다음에</LaterButton>
                    <GoSettingButton onClick={() => {
                        navigate("/setting/sport/favorite")
                    }}>세팅 하러가기</GoSettingButton>
                </ButtonContainer>
            </PageLayout>
        </>
    );
}

const IntroContainer = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Title = styled.h2`
    font-size: var(--font-size-4);
    color: white;
    margin-top: 1rem;
`;

const IntroMessage = styled.div`
    font-size: var(--font-size-7);
    color: var(--system-gray);
    text-align: center;
`
const LaterButton = styled.button`
    width: 7rem;
    height: 5rem;
    background: var(--system-black);
    color: var(--system-gray);
    border-radius: 1rem;
    border: none;
    font-size: var(--font-size-6);
`;

const GoSettingButton = styled.button`
    flex-grow: 1;
    width: 10rem;
    height: 5rem;
    background: var(--brand-blue);
    font-weight: bold;
    font-size: var(--font-size-6);
    color: white;
    border-radius: 1rem;
    border: none;
    margin-left: 0.5rem;
`;

const ButtonContainer = styled.div`
    padding: 0 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

const Empty = styled.div`
`;

const PageDescribe = styled.div`
    //margin-bottom: 0.5rem;
    color: white;
    font-size: var(--font-size-5);
    z-index: 15;
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    height: 3rem;
    width: 100%;
`;

const PeopleIcon = styled.img`
    width: 13rem;
    height: 13rem;
`;

const PageLayout = styled.div<{ splash: string }>`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 3rem;
    align-items: center;
    transition: opacity 0.7s;
    opacity: ${props => props.splash == "ON" ? 0 : 1};
    
`;

const LogoWrapper = styled.div<{ splash: string }>`
    position: fixed;
    background: var(--system-deepblack);
    transition: opacity 0.7s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: ${props => props.splash == "ON" ? 1 : 0};
    z-index: ${props => props.splash == "ON" ? 100 : -1};
    justify-content: center;
    align-items: center;
`;

const Icon = styled.div`
    font-size: var(--font-size-3);
    color: #fff;
    font-weight: 900;
`;


const Highlight = styled.span`
    color: var(--brand-blue);
`;


export default SignUpV2Page;