import React from "react";
import styled from "styled-components";

interface ConfirmFormProps {
    message: string;
    action: () => void;
    close: () => void;
}

export const ConfirmForm = ({message, action, close}: ConfirmFormProps) => {
    return (
        <Container>
            <InputBox>{message}</InputBox>
            <ConfirmButton onClick={() => {
                action();
                close();
            }}>확인
            </ConfirmButton>
        </Container>
    );
}


const Container = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`

const InputBox = styled.div`
    width: 100%;
    text-align: center;
`

const ConfirmButton = styled.button`
    width: 5rem;
    height: 2rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
`