import styled from "styled-components";

export interface UserProfileImgProps {
    url: string;
    height: number;
    width: number;
    color?: string;
}


export const CircleImg: React.FC<UserProfileImgProps> = ({ url, width, height, color }: UserProfileImgProps) => {
    const outerCircleColor = color ? color : "var(--brand-blue)";
    return (
        <OutCircle width={width} height={height} color={outerCircleColor} >
            <InnerCircle $url={url} width={width} height={height}/>
        </OutCircle>
    );
};

const OutCircle = styled.div<{ width: number; height: number, color: string }>`
  width: calc(${props => props.width}rem + 4px);
  height: calc(${props => props.height}rem + 4px);
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerCircle = styled.div<{ width: number, height: number, $url: string }>`
  width: ${props => props.width}rem;
  height: ${props => props.height}rem;
  border-radius: 50%;
  background: url(${props => props.$url}) center center / cover;
`;
