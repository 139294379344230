import React, {useEffect} from "react";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {userState} from "../state/userState";
import {fetchUsers} from "../api/users";
import {ISelectUser, IUser} from "../types/users";
import {IPlayer, EMatchBuildStep} from "../types/match";
import {BasketballUserSelect} from "../components/match/basketball/BasketballUserSelect";
import {sleep} from "../utils";
import {useNavigate} from "react-router-dom";
import {BasketballTeamOrganization} from "../components/match/basketball/BasketballTeamOrganization";


export const BasketballMatchBuildPage: React.FC = () => {
    const navigate = useNavigate();

    const loginUser = useRecoilValue(userState);

    // step 1, 2, 3
    const [step, setStep] = React.useState<EMatchBuildStep>(EMatchBuildStep.USER_SELECT);

    const [isLoading, setIsLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState('페이지 로딩중입니다');

    // step 1 - 게임에 참여할 유저 목록
    const [users, setUsers] = React.useState<ISelectUser[]>([]);

    useEffect(() => {
        setIsLoading(true);

        initData().then(() => {
            setIsLoading(false);
        });
    }, [])

    /** 페이지를 그리기 위해 필요한 데이터들 설정 */
    const initData = async () => {
        try {
            const [_users, _] = await Promise.all([
                fetchUsers(),
                sleep(1),   // 사용자 경험을 위한 1초 대기
            ])

            setUsers(_users.users.map(user => {
                return {
                    ...user,
                    selected: false,
                }
            }));

        } catch (error) {
            console.log(error);
        }

    }

    return isLoading ? (
        <LoadingPage>{loadingMessage}</LoadingPage>
    ) : (
        <Wrapper>
            {/*<BasketballUserSelectWrapper selected={step === MatchStep.USER_SELECT}/>*/}
            {step === EMatchBuildStep.USER_SELECT && (
                <BasketballUserSelect
                    key={'basketball-user-select'}
                    users={users}
                    handleAnonymousUserAddButtonClick={(newUser: ISelectUser) => {
                        setUsers(prev => [newUser, ...prev])
                    }}
                    handleNavigateNextStep={(users: ISelectUser[]) => {
                        setStep(EMatchBuildStep.TEAM_ORGANIZATION)
                        setUsers([...users])
                    }}
                    updateUsers={(newUsers: ISelectUser[]) => {
                        setUsers([...newUsers])
                    }}
                />
            )}
            {step === EMatchBuildStep.TEAM_ORGANIZATION && (
                <BasketballTeamOrganization
                    key={'basketball-team-organization'}
                    step={step}
                    users={users}
                    navigateNextStep={(matchId: number) => navigate(`/match/basketball/${matchId}`)}
                    navigatePrevStep={() => setStep(EMatchBuildStep.USER_SELECT)}
                    selected={true}
                />
            )}
        </Wrapper>
    )
}


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const BasketballUserSelectWrapper = styled.div<{ selected: boolean }>`
`;

const BasketballTeamOrganizationWrapper = styled.div<{ selected: boolean }>`
`;

const BasketballMatchWrapper = styled.div<{ selected: boolean }>`
`;

const LoadingPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 1rem;
`;
