import React from 'react';
import styled from "styled-components";
import {ICandidatePlayer, ITeam} from "../types/match";

interface Props {
    selected?: boolean;
    user: ICandidatePlayer;
    teamOneClickEventHandler: () => void;
    teamTwoClickEventHandler: () => void;
    teamOne: ITeam;
    teamTwo: ITeam;
}

export const CandidatePlayer = ({selected, user, teamOneClickEventHandler, teamTwoClickEventHandler, teamOne, teamTwo}: Props) => {
    return (
        <Player selected={!!selected}>
            <UserName>{user.name}</UserName>
            <TeamSelectButton onClick={teamOneClickEventHandler}>{teamOne.teamName}팀</TeamSelectButton>
            <TeamSelectButton onClick={teamTwoClickEventHandler}>{teamTwo.teamName}팀</TeamSelectButton>
        </Player>
    )
}

const Player = styled.div<{selected: boolean}>`
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    display: ${props => props.selected ? 'none' : 'flex'};
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid gray;
    border-radius: 0.5rem;
`

const UserName = styled.div`
    width: 100%;
    height: 1.5rem;
    //text-align: left;
`

const TeamSelectButton = styled.button`
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    height: 1.5rem;
`