import React from "react";
import {api, fetchLogin, LoginResponse} from "../axiosInstance";
import {AxiosError} from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {sleep} from "../utils";
import {userState} from "../state/userState";
import {useSetRecoilState} from "recoil";
import {LabeledInput} from "../components/atom/LabeledInput";
import {DescribedTitle} from "../components/atom/DescribedTitle";
import {DEFAULT_ID_WARNING_MESSAGE, DEFAULT_PASSWORD_WARNING_MESSAGE} from "../constants";


export const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [errorMessage, setErrorMessage] = React.useState('');

    const setUser = useSetRecoilState(userState);

    const from  = location.state?.from?.pathname || '/home';

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setUsername(value)
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPassword(value)
    }

    const login = async () => {
        try {
            const {token, name, id}: LoginResponse = await fetchLogin(username, password);
            const user = {userId: id.toString(), name, token}
            setUser(user);

            navigate(from, {replace: true});
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 404) {
                setErrorMessage('아이디를 확인해주세요')
            } else if (axiosError.response?.status === 400) {
                setErrorMessage('비밀번호를 확인해주세요.')
            } else {
                setErrorMessage('잠시 후 다시 시도해주세요.')
            }
        }
    }

    const handleLoginButtonClick = async () => {
        await login();
    }

    const handleLoginEnterKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await login();
        }
    }

    return (
        <Container>
            <DescribedTitle title='로그인' description='나의 스포츠 데이터를 기록 하세요.'/>
            <InputWrapper>
                <LabeledInput
                    type='text'
                    label='ID'
                    placeholder='아이디'
                    value={username}
                    onChange={handleUsernameChange}
                    warning={true}
                    warningMessage={DEFAULT_ID_WARNING_MESSAGE}
                    warningCondition={true}
                ></LabeledInput>
            </InputWrapper>
            <InputWrapper>
                <LabeledInput
                    label='PASSWORD'
                    type="password"
                    placeholder='비밀번호'
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleLoginEnterKeyDown}
                    warning={true}
                    warningMessage={DEFAULT_PASSWORD_WARNING_MESSAGE}
                    warningCondition={password.length < 8}
                />
            </InputWrapper>
            <InputWrapper>
                <Button onClick={handleLoginButtonClick}>로그인</Button>
            </InputWrapper>
            <ErrorMessage>{errorMessage}</ErrorMessage>
        </Container>
    );
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    width: 100%;
`;


const InputWrapper = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
  max-width: 400px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.div`
    color: red;
    
`;

export default LoginPage;